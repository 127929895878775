import * as Util from '../../util';
import Cookies from 'js-cookie';

let w = window,
    euconsent_v2;

export default class IABCmp {
    constructor() {
    }

    setListenersForPartners() {
        let partnersInterval;
        partnersInterval = setInterval(() => {
            if (window.__tcfapi) {
                this.setHandlers();
                clearInterval(partnersInterval);
            }
        }, 100);
    }

    sendMetric(val) {
        let toSend = 0;
        switch (val) {
            case 'all':
                toSend = 2;
                break;
            case 'some':
                toSend = 1;
                break;
            default:
                toSend = 0;
                break;
        }

        let reportRequest = new XMLHttpRequest(),
            qs = 's=' + window.location.host;

        qs += `&v=${toSend}`;

        let goliathUrl = '#{GOLIATH}';
        if (goliathUrl) {
            reportRequest.open('GET', goliathUrl + '?' + qs);
            reportRequest.send();
        }
    }

    setHandlers() {
        let that = this;

        window.__tcfapi('addEventListener', 2, function (tcData, success) {
            if (tcData.eventStatus == 'cmpuishown') {
                let escapeKeyInterval;
                escapeKeyInterval = setInterval(() => {
                    if (!!document.querySelector('#qc-cmp2-main')) {
                        document.querySelector('#qc-cmp2-main').addEventListener('keydown', function (event) {
                            if (event.key === 'Escape') {
                                event.preventDefault();
                                event.stopPropagation();
                            }
                        }, true);
                        clearInterval(escapeKeyInterval);
                    }
                }, 100);
            }

            if (tcData.eventStatus == 'useractioncomplete' || tcData.eventStatus == 'tcloaded') {
                let vendors = tcData.vendor.consents,
                    allowed = 0,
                    total = 0,
                    key;
                for (key in vendors) {
                    if (vendors.hasOwnProperty(key)) {
                        if (vendors[key] === true) {
                            allowed++;
                        }
                        total++;
                    }
                }

                if (window.dataLayer) {
                    const gtag = function () {
                        window.dataLayer.push(arguments);
                    };
                    let analytics_storage_value = 'denied',
                        ad_storage_value = 'denied';

                    if (tcData.purpose && tcData.purpose.consents) {
                        let adStoragePurposes = [2, 3, 4, 7];
                        adStoragePurposes.forEach(idx => {
                            if (tcData.purpose.consents[idx]) {
                                ad_storage_value = 'granted';
                            }
                        })

                        if (tcData.purpose.consents[8] === true) {
                            analytics_storage_value = 'granted';
                        }
                    }

                    gtag('consent', 'update', {
                        'ad_storage': ad_storage_value,
                        'analytics_storage': analytics_storage_value
                    });
                }

                let consentedAll = total - allowed <= 0;
                Util.createEvent('showFooter', !consentedAll);
                that.sendMetric(consentedAll ? 'all' : 'some');

                //rotate bsu logo
                if (window.location.hash === '#bsu') {
                    if (window.location.host !== 'www.sapo.pt') {
                        let newsletterButtons = document.querySelectorAll('[data-snl-color]');
                        if (newsletterButtons.length > 0) {
                            let color = newsletterButtons[0].getAttribute('data-snl-color');
                            if (color) {
                                setTimeout(function () {
                                    SAPO.BSU.rotateLogoColor(color);
                                }, 3000);
                            }
                        }
                    }
                }

                document.querySelectorAll('#qc-cmp2-global-styles').forEach(elm => elm.remove());
            }
        });
    }

    inject() {
        (function () {
            try {
                window["gtag_enable_tcf_support"] = true;

                var host = window.location.hostname;
                var element = document.createElement("script");
                var firstScript = document.getElementsByTagName("script")[0];
                var url = "https://cmp.inmobi.com".concat(
                    "/choice/",
                    '#{INMOBI_KEY}',
                    "/",
                    host,
                    "/choice.js?tag_version=V3",
                );
                var uspTries = 0;
                var uspTriesLimit = 3;
                element.async = true;
                element.type = "text/javascript";
                element.src = url;

                firstScript.parentNode.insertBefore(element, firstScript);

                function makeStub() {
                    var TCF_LOCATOR_NAME = "__tcfapiLocator";
                    var queue = [];
                    var win = window;
                    var cmpFrame;

                    function addFrame() {
                        var doc = win.document;
                        var otherCMP = !!win.frames[TCF_LOCATOR_NAME];

                        if (!otherCMP) {
                            if (doc.body) {
                                var iframe = doc.createElement("iframe");

                                iframe.style.cssText = "display:none";
                                iframe.name = TCF_LOCATOR_NAME;
                                doc.body.appendChild(iframe);
                            } else {
                                setTimeout(addFrame, 5);
                            }
                        }
                        return !otherCMP;
                    }

                    function tcfAPIHandler() {
                        var gdprApplies;
                        var args = arguments;

                        if (!args.length) {
                            return queue;
                        } else if (args[0] === "setGdprApplies") {
                            if (
                                args.length > 3 &&
                                args[2] === 2 &&
                                typeof args[3] === "boolean"
                            ) {
                                gdprApplies = args[3];
                                if (typeof args[2] === "function") {
                                    args[2]("set", true);
                                }
                            }
                        } else if (args[0] === "ping") {
                            var retr = {
                                gdprApplies: gdprApplies,
                                cmpLoaded: false,
                                cmpStatus: "stub",
                            };

                            if (typeof args[2] === "function") {
                                args[2](retr);
                            }
                        } else {
                            if (
                                args[0] === "init" &&
                                typeof args[3] === "object"
                            ) {
                                args[3] = { ...args[3], tag_version: "V3" };
                            }
                            queue.push(args);
                        }
                    }

                    function postMessageEventHandler(event) {
                        var msgIsString = typeof event.data === "string";
                        var json = {};

                        try {
                            if (msgIsString) {
                                json = JSON.parse(event.data);
                            } else {
                                json = event.data;
                            }
                        } catch (ignore) { }

                        var payload = json.__tcfapiCall;

                        if (payload) {
                            window.__tcfapi(
                                payload.command,
                                payload.version,
                                function (retValue, success) {
                                    var returnMsg = {
                                        __tcfapiReturn: {
                                            returnValue: retValue,
                                            success: success,
                                            callId: payload.callId,
                                        },
                                    };
                                    if (msgIsString) {
                                        returnMsg = JSON.stringify(returnMsg);
                                    }
                                    if (
                                        event &&
                                        event.source &&
                                        event.source.postMessage
                                    ) {
                                        event.source.postMessage(
                                            returnMsg,
                                            "*",
                                        );
                                    }
                                },
                                payload.parameter,
                            );
                        }
                    }

                    while (win) {
                        try {
                            if (win.frames[TCF_LOCATOR_NAME]) {
                                cmpFrame = win;
                                break;
                            }
                        } catch (ignore) { }

                        if (win === window.top) {
                            break;
                        }
                        win = win.parent;
                    }
                    if (!cmpFrame) {
                        addFrame();
                        win.__tcfapi = tcfAPIHandler;
                        win.addEventListener(
                            "message",
                            postMessageEventHandler,
                            false,
                        );
                    }
                }

                makeStub();

                var uspStubFunction = function () {
                    var arg = arguments;
                    if (typeof window.__uspapi !== uspStubFunction) {
                        setTimeout(function () {
                            if (typeof window.__uspapi !== "undefined") {
                                window.__uspapi.apply(window.__uspapi, arg);
                            }
                        }, 500);
                    }
                };

                var checkIfUspIsReady = function () {
                    uspTries++;
                    if (
                        window.__uspapi === uspStubFunction &&
                        uspTries < uspTriesLimit
                    ) {
                        console.warn("USP is not accessible");
                    } else {
                        clearInterval(uspInterval);
                    }
                };

                if (typeof window.__uspapi === "undefined") {
                    window.__uspapi = uspStubFunction;
                    var uspInterval = setInterval(checkIfUspIsReady, 6000);
                }
            } catch (e) { }
        })();

        this.setHandlers();
    }
}
