import * as Util from '../util.js';
import * as footerConfig from './footer-config';
import * as footerLinksConfig from './footer-links-config';

export default class Footer {
    constructor(container, country, footerAnalytics, lang, linksContainer) {
        this.$container = container;
        this.$linksContainer = linksContainer;
        this.country = country;
        this.footerAnalytics = footerAnalytics;
        this.lang = lang;
    }

    build() {
        if (!this.$container) {
            console.warn(`Footer container does not exist in page`);
        } else {
            let dataBG = this.$container.getAttribute('data-bg');
            if (dataBG !== 'dark') {
                dataBG = 'light';
            }

            this.$container.addClass('bsu-footer-' + dataBG);

            let $logo = document.createElement('a');

            $logo.addClass('bsu-footer-logo');
            $logo.setAttribute('href', 'https://www.sapo.' + this.country + this.footerAnalytics);
            $logo.setAttribute('rel', 'nofollow');
            $logo.appendChild(document.createTextNode('SAPO'));

            let $div = document.createElement('div');
            $div.addClass('bsu-footer-text');

            if (
                this.lang &&
                typeof footerConfig.lang === 'object' &&
                this.lang in footerConfig.lang &&
                footerConfig.lang[this.lang]
            ) {
                $div.innerHTML = footerConfig.lang[this.lang].replace('www.sapo.pt', 'www.sapo.' + this.country);
            } else {
                $div.innerHTML = footerConfig.text.replace('www.sapo.pt', 'www.sapo.' + this.country);
            }

            this.$container.appendChild($logo);
            this.$container.appendChild($div);
        }
        this.buildLinks();
    }

    buildLinks() {
        if (!this.$linksContainer) {
            console.warn(`Footer Links container does not exist in page`);
        } else {
            let aFinalFooterLinks = [],
                dataExtras = [],
                dataLinkChanges = [],
                footerLinks = footerLinksConfig;

            for (let idx = 0; idx < 10; ++idx) {
                let extraLink = !idx
                        ? this.$linksContainer.getAttribute('data-link-extra')
                        : this.$linksContainer.getAttribute('data-link-extra-' + idx),
                    linkChange = !idx
                        ? this.$linksContainer.getAttribute('data-link-change')
                        : this.$linksContainer.getAttribute('data-link-change-' + idx);

                extraLink && dataExtras.push(extraLink);
                linkChange && dataLinkChanges.push(linkChange);
            }

            dataLinkChanges.forEach((linkChange) => {
                let parts = linkChange.split('|');
                if (footerLinks[parts[0]] && parts[1]) {
                    footerLinks[parts[0]].href = parts[1];
                }
            });

            aFinalFooterLinks = Object.keys(footerLinks).reduce((prev, curr) => {
                return prev.push(footerLinks[curr]) && prev;
            }, []);

            dataExtras.forEach((extraLink) => {
                let oExtra = _parseDataAttrFooterLinks(extraLink, 'extra');
                if (oExtra) {
                    aFinalFooterLinks.push(oExtra);
                }
            });

            let curLink, $li, $a;
            for (let i = 0; i < aFinalFooterLinks.length; i++) {
                curLink = aFinalFooterLinks[i];
                $li = document.createElement('li');
                $a = document.createElement('a');
                if (curLink.text && curLink.href) {
                    if (/^mailto\:.*/i.test(curLink.href) || /^javascript\:.*/i.test(curLink.href)) {
                        $a.setAttribute('href', curLink.href);
                    } else {
                        $a.setAttribute('href', curLink.href + this.footerAnalytics);
                    }
                    $a.setAttribute('rel', 'nofollow');

                    if (curLink.liclass) {
                        $li.addClass(curLink.liclass);
                    }
                    if (curLink.liclass) {
                        $li.setAttribute('id', curLink.liid);
                    }
                    if (this.lang && typeof curLink.lang === 'object' && this.lang in curLink.lang && curLink.lang[this.lang]) {
                        $a.innerHTML = curLink.lang[this.lang];
                    } else {
                        $a.innerHTML = curLink.text;
                    }

                    $li.appendChild($a);
                    this.$linksContainer.appendChild($li);
                }
            }
        }
    }
}

function _parseDataAttrFooterLinks(attr, changeOrExtra) {
    let aAttr = attr.split('|'),
        oOpt = {};
    if (changeOrExtra === 'change') {
        if (aAttr.length !== 2) {
            return;
        }
        oOpt.idx = aAttr[0].toLowerCase();
        oOpt.href = aAttr[1];

        return oOpt;
    } else if (changeOrExtra === 'extra') {
        if (aAttr.length < 2) {
            return;
        }
        let _href = aAttr[0] ? aAttr[0] : '',
            _text = aAttr[1] ? aAttr[1] : '';
        if (_href && _text) {
            oOpt.text = _text;
            oOpt.href = _href;

            oOpt.liclass = aAttr[2] ? aAttr[2] : '';
            oOpt.liid = aAttr[3] ? aAttr[3] : '';

            return oOpt;
        }
    }
    return false;
}
