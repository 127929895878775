import * as Util from '../../util.js';
import { menuClasses } from '../../classes-ids.config.js';

export default class Login {
    constructor(bsu) {
        this.bsuWidget = bsu;
        this.$userMenu = Util.getById(menuClasses.userMenu.id);
        this.$userAvatar = Util.getById(menuClasses.userMenu.avatarId);
        this.$userImage = Util.getElement(`#${menuClasses.userMenu.avatarId} img`);
        this.$userLi = Util.getById(menuClasses.userMenu.userAreaId);
        this.$userMenuOptions = Util.getById(menuClasses.userMenu.userMenuOptionsId);
        this.areaLoggedInClass = 'bsu-v3-icon-user--logged-in';
        this.iframeId = 'loginIframe';
        this.failedAuthentication = false; //para ser usado nos Unit Tests uma vez que o window.location.href manca-se com o JSDOM

    }

    init() {
        if (!Util.isBelowIE9()) {
            return Promise.resolve()
                .then(() => attachEventHandlers.call(this))
                .then(() => this.bsuWidget.authStartup.LoggedIn === true ? fillUserArea.call(this, this.bsuWidget.authStartup) : null)
                .then(() => buildHTML.call(this))
                .catch((e) => console.warn(`Erro no Login : ${e}`));
        } else { //IE<10
            return Promise.resolve()
                .then(() => buildHTML.call(this))
                .then(() => attachEventHandlers.call(this))
                .then(() => this.bsuWidget.authStartup ? createLoginIframe.call(this) : this.$userMenu.removeClass('bsu-v3-hide-all'))
                .catch((e) => console.warn(`Erro no Login : ${e}`));
        }
    }
}

function unicodeToChar(text) {
    return text.replace(/\\u[\dA-F]{4}/gi,
        function (match) {
            return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
        });
}

function fillUserArea(userInfo) {
    let regex = /(<|>|\t|\r|\n|\\)/g;
    userInfo.Username = userInfo.Username ? unicodeToChar(userInfo.Username) :
        userInfo.name ? unicodeToChar(userInfo.name) : 'Utilizador';

    userInfo.Avatar = userInfo.Avatar ? userInfo.Avatar.replace(regex, '') :
        userInfo.avatar ? userInfo.avatar.replace(regex, '') : null;

    this.$userAvatar.setAttribute('alt', userInfo.Username);
    this.$userAvatar.setAttribute('title', userInfo.Username);
    this.$userImage.setAttribute('src', userInfo.Avatar || userInfo.avatar);
    this.$userAvatar.addClass(this.areaLoggedInClass);
    this.$userMenu.removeClass('bsu-v3-hide-all');
}

function buildHTML() {
    let logoutUrl = `${this.bsuWidget._config.loginEndpoint}/Logout.do?to=${encodeURIComponent(this.bsuWidget._dataOptions.logoutUrl)}`;

    this.$userMenuOptions.innerHTML = `
        <li>
            <a id="${menuClasses.userMenu.myEmailId}" href="https://mail.sapo.pt/login?loginType=bsu&source=bsu_login">O meu e-mail</a>
        </li>
        ${this.bsuWidget._dataOptions.country == 'pt' ?
            `<li>
                <a href="${this.bsuWidget._config.protocol}//sapo.pt/newsletters${this.bsuWidget._analytics._confHashLoginAreaAnalytics}">As minhas newsletters</a>
            </li>` : ''
        }
        <li>
            <a href="${this.bsuWidget._config.protocol}//blogs.sapo.${this.bsuWidget._dataOptions.country}/dashboard${this.bsuWidget._analytics._confHashLoginAreaAnalytics}">Os meus blogs</a>
        </li>
        ${JSON.parse(this.bsuWidget.authStartup.CanChangePassword) ?
            `<li>
            <a href="${this.bsuWidget._config.idEndPoint}/a/security${this.bsuWidget._analytics._confHashLoginAreaAnalytics}">Alterar password</a>
        </li>` : ''
        }
        <li>
            <a href="${this.bsuWidget._config.idEndPoint}/a/profile${this.bsuWidget._analytics._confHashLoginAreaAnalytics}">Editar perfil</a>
        </li>
        <li>
            <a id="${menuClasses.userMenu.logoutId}" target="_self" href="${logoutUrl}">Sair</a>
        </li>`;
    this.$userMenu.removeClass('bsu-v3-hide-all');

    Util.addEvent(this.$userAvatar, 'click', IEuserAvatarCallback.bind(this));

    var logoutButton = Util.getById(menuClasses.userMenu.logoutId);
    Util.addEvent(logoutButton, 'click', LogoutButtonClicked.bind(this));

}

function attachEventHandlers() {
    this.$userAvatar.setAttribute('target', '_self');
    this.$userAvatar.setAttribute('href', `${this.bsuWidget._config.loginEndpoint}/Login.do?to=${encodeURIComponent(this.bsuWidget._dataOptions.loginUrl)}`);

    Util.addEvent(document, 'click', e => {
        if (!e.target.closest(`#${menuClasses.userMenu.userAreaId}`))
            this.$userLi.removeClass(menuClasses.openedSubmenu);
    });

    if (!Util.isBelowIE9()) {
        Util.addEvent(this.$userAvatar, 'click', userAvatarCallback.bind(this));
        Util.addEvent(document, 'mailOpen', userInfo => {
            if (userInfo.state.LoggedIn === true) {
                fillUserArea.call(this, userInfo.state);
            } else {
                failedAuthentication.call(this);
            }
        });
    } else {
        Util.addEvent(window, 'message', e => iframeEventHandler.call(this, e));
        Util.addEvent(this.$userAvatar, 'click', IEuserAvatarCallback.bind(this));
        Util.addEvent(document, 'mailOpen', e => createLoginIframe.call(this));
    }
}

function LogoutButtonClicked(e) {
    var iframe = Util.getById('mailIframe');
    iframe && iframe.contentWindow.postMessage('bsuLogoutButtonClicked', '*');
}

function userAvatarCallback(e) {
    e.preventDefault();
    if (this.$userLi.hasClass(menuClasses.openedSubmenu)) {
        this.$userLi.removeClass(menuClasses.openedSubmenu);
    } else {
        Util.getUserInfo().then(userInfo => {
            if (userInfo.LoggedIn === true) {
                fillUserArea.call(this, userInfo);
                this.$userLi.addClass(menuClasses.openedSubmenu);
            } else {
                failedAuthentication.call(this);
            }
        });
    }
}

function failedAuthentication() {
    this.failedAuthentication = true;
    window.location.href = this.$userAvatar.getAttribute('href');
}


/** IE LOGIN */

function createLoginIframe() {
    // let iframe = Util.getById(this.iframeId);
    // if (iframe) iframe.parentNode.removeChild(iframe);

    let paramsIframe = [
        `ref=${encodeURIComponent(this.bsuWidget._config.protocol + '//' + this.bsuWidget._config.hostname)}`,
        `source=${encodeURIComponent(window.location.href)}`,
        `login=${encodeURIComponent(this.bsuWidget._dataOptions.loginUrl)}`,
        `logout=${encodeURIComponent(this.bsuWidget._dataOptions.logoutUrl)}`,
        `_r=${(new Date()).getTime()}`
    ];

    Util.createAndAttachIframe({
        id: this.iframeId,
        src: `${this.bsuWidget._config.loginEndpoint}${this.bsuWidget._config.iframePath}?${paramsIframe.join('&')}`,
        width: 0,
        height: 0,
        frameBorder: 0,
        border: 0,
    }, this.$userLi);
}

function iframeEventHandler(event) {

    if (event.origin.indexOf(this.bsuWidget._config.loginURL) > -1) {
        let message, userInfo;
        if (event.data.indexOf('message') > -1) {
            let json = JSON.parse(event.data);
            userInfo = json.data;
            message = json.message;
        }
        switch (message) {
            case 'userInfo':
                fillUserArea.call(this, userInfo);
                break;
            case 'logout':
                break;
        }
    }
}

function IEuserAvatarCallback(e) {
    e.preventDefault();
    if (this.$userLi.hasClass(menuClasses.openedSubmenu)) {
        this.$userLi.removeClass(menuClasses.openedSubmenu);
    } else {
        Util.isLogged().then(auth => {
            if (auth) {
                createLoginIframe.call(this);
                var iframe = Util.getById(this.iframeId);
                //iframe.onload = () => {
                this.$userLi.addClass(menuClasses.openedSubmenu);
                //}
            }
            else {
                failedAuthentication.call(this);
            }
        })
    }
}