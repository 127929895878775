module.exports = {
    sep: {},
    logo: {
        pt: 'https://www.sapo.pt',
        title: 'SAPO.pt - Principais notícias de hoje, em Portugal e no mundo, de última hora e atualizadas ao minuto',
        name: 'SAPO'
    },
    mail: {
        pt: 'https://mail.sapo.pt/login?loginType=bsu',
        mz: 'https://mail.sapo.mz',
        ao: 'https://mail.sapo.ao',
        tl: 'https://mail.sapo.tl',
        cv: 'https://mail.sapo.cv',
        title: 'Mail',
        name: 'SAPO Mail'
    },
    blogs: {
        pt: 'https://blogs.sapo.pt',
        mz: 'https://blogs.sapo.mz',
        ao: 'https://blogs.sapo.ao',
        tl: 'https://blogs.sapo.tl',
        cv: 'https://blogs.sapo.cv',
        title: 'Blogs',
        name: 'SAPO Blogs'
    },
    tempo: {
        pt: 'https://tempo.sapo.pt',
        mz: 'https://tempo.sapo.mz',
        ao: 'https://tempo.sapo.ao',
        tl: 'https://tempo.sapo.tl',
        cv: 'https://tempo.sapo.cv',
        title: 'Tempo',
        name: 'SAPO Tempo'
    },
    farmacias: {
        pt: 'https://farmacias.sapo.pt',
        title: 'Farmácias',
        name: 'SAPO Farmácias'
    },
    sapo24: {
        pt: 'https://24.sapo.pt',
        title: '24',
        name: 'SAPO 24'
    },
    noticias: {
        mz: 'https://noticias.sapo.mz',
        ao: 'https://noticias.sapo.ao',
        cv: 'https://noticias.sapo.cv',
        title: 'Notícias',
        name: 'SAPO Notícias',
        //new: { pt: true }
    },
    desporto: {
        pt: 'https://desporto.sapo.pt',
        mz: 'https://desporto.sapo.mz',
        ao: 'https://desporto.sapo.ao',
        cv: 'https://desporto.sapo.cv',
        title: 'Desporto',
        name: 'SAPO Desporto'
    },
    lifestyle: {
        pt: 'https://lifestyle.sapo.pt',
        mz: 'https://lifestyle.sapo.mz',
        ao: 'https://lifestyle.sapo.ao',
        cv: 'https://lifestyle.sapo.cv',
        title: 'Lifestyle',
        name: 'SAPO Lifestyle'
        //new: { pt: true}
    },
    mag: {
        pt: 'https://mag.sapo.pt',
        title: 'Mag',
        name: 'SAPO Mag'
    },
    muzika: {
        cv: 'https://muzika.sapo.cv',
        title: 'Muzika',
        name: 'SAPO Muzika',
        //new: { cv: true }
    },
    viagens: {
        pt: 'https://viagens.sapo.pt',
        ao: 'https://viagens.sapo.ao',
        mz: 'https://viagens.sapo.mz',
        cv: 'https://viagens.sapo.cv',
        title: 'Viagens',
        name: 'SAPO Viagens'
    },
    tek: {
        pt: 'https://tek.sapo.pt',
        title: 'Tek',
        name: 'SAPO Tek'
    },
    promos: {
        pt: 'https://promos.sapo.pt',
        title: 'Promos',
        name: 'SAPO Promos'
    },
    jornais: {
        pt: 'https://www.sapo.pt/jornais',
        ao: 'https://www.sapo.pt/jornais',
        mz: 'https://www.sapo.pt/jornais',
        cv: 'https://www.sapo.pt/jornais',
        title: 'Jornais',
        name: 'SAPO Jornais'
    },
    auto: {
        pt: 'https://auto.sapo.pt',
        ao: 'https://auto.sapo.ao',
        cv: 'https://auto.sapo.cv',
        title: 'Carros',
        name: 'Auto SAPO'
    },
    automotos: {
        pt: 'https://auto.sapo.pt/motos',
        name: 'Auto SAPO Motos'
    },
    autobarcos: {
        pt: 'https://auto.sapo.pt/barcos',
        name: 'Auto SAPO Barcos'
    },
    autopremium: {
        pt: 'https://auto.sapo.pt/premium',
        name: 'Auto SAPO Premium'
    },
    autovendaja: {
        pt: 'https://auto.sapo.pt/VendaJa',
        name: 'Auto SAPO Venda Já'
    },
    premiossapo2023: {
        pt: 'https://tek.sapo.pt/extras/site-do-dia/artigos/os-premios-sapo-tem-este-ano-uma-edicao-inedita-nos-santos-populares',
        title: 'Prémios SAPO 2023',
        name: 'Prémios SAPO 2023'
    },
    casa: {
        pt: 'https://supercasa.pt',
        mz: 'https://supercasa.pt',
        ao: 'https://supercasa.pt',
        cv: 'https://supercasa.pt',
        title: 'Casas',
        name: 'SUPERCASA'
    },
    emprego: {
        pt: 'https://emprego.sapo.pt',
        mz: 'https://emprego.sapo.mz',
        ao: 'https://emprego.sapo.ao',
        cv: 'https://emprego.sapo.cv',
        title: 'Emprego',
        name: 'SAPO Emprego'
    },
    renascenca: {
        pt: 'https://rr.sapo.pt',
        title: 'Renascença',
        name: 'Renascença'
    },
    jornali: {
        pt: 'https://ionline.sapo.pt',
        title: 'Jornal i',
        name: 'Jornal i'
    },
    sol: {
        pt: 'https://sol.sapo.pt',
        title: 'Sol',
        name: 'Sol'
    },
    portocanal: {
        pt: 'http://portocanal.sapo.pt',
        title: 'Porto Canal',
        name: 'Porto Canal'
    },
    henricartoon: {
        pt: 'https://henricartoon.pt',
        title: 'HenriCartoon',
        name: 'HenriCartoon'
    },
    videos: {
        pt: 'https://videos.sapo.pt',
        ao: 'https://videos.sapo.ao',
        mz: 'https://videos.sapo.mz',
        cv: 'https://videos.sapo.cv',
        title: 'Vídeos',
        name: 'SAPO Vídeos'
    },
    bolabranca: {
        pt: 'https://rr.sapo.pt/bolabranca',
        title: 'Bola Branca',
        name: 'Bola Branca'
    },
    superbrands: {
        pt: 'https://superbrands.sapo.pt/',
        title: 'Superbrands',
        name: 'Superbrands'
    },
    economico: {
        pt: 'https://jornaleconomico.sapo.pt',
        title: 'Jornal Económico',
        name: 'Jornal Económico'
    },
    eco: {
        pt: 'https://eco.sapo.pt',
        title: 'ECO',
        name: 'ECO'
    },
    elle: {
        pt: 'https://elle.sapo.pt',
        title: 'Elle',
        name: 'Elle'
    },
    mood: {
        pt: 'https://mood.sapo.pt',
        title: 'Mood',
        name: 'Mood'
    },
    greensavers: {
        pt: 'https://greensavers.sapo.pt',
        title: 'Green Savers',
        name: 'Green Savers'
    },
    rockinrio: {
        pt: 'https://rockinriolisboa.sapo.pt',
        title: 'Rock in Rio',
        name: 'Rock in Rio'
    },
    pplware: {
        pt: 'https://pplware.sapo.pt',
        title: 'Pplware',
        name: 'Pplware'
    },
    exame: {
        pt: 'https://visao.sapo.pt/exame',
        title: 'Exame',
        name: 'Exame'
    },
    exameinformatica: {
        pt: 'https://visao.sapo.pt/exameinformatica/',
        title: 'Exame Informática',
        name: 'Exame Informática'
    },
    caras: {
        pt: 'https://caras.sapo.pt',
        title: 'Caras',
        name: 'Caras'
    },
    activa: {
        pt: 'https://activa.sapo.pt',
        title: 'Activa',
        name: 'Activa'
    },
    tvmais: {
        pt: 'https://tvmais.sapo.pt',
        title: 'TV Mais',
        name: 'TV Mais'
    },
    telenovelas: {
        pt: 'https://telenovelas.sapo.pt',
        title: 'Telenovelas',
        name: 'Telenovelas'
    },
    canalq: {
        pt: 'http://videos.sapo.pt/canalq',
        title: 'Canal Q',
        name: 'Canal Q'
    },
    poligrafo: {
        pt: 'https://poligrafo.sapo.pt',
        title: 'Polígrafo',
        name: 'Polígrafo'
    },
    kbb: {
        pt: 'https://kbb.sapo.pt',
        title: 'KBB',
        name: 'KBB'
    },
    motosport: {
        pt: 'https://motosport.pt',
        title: 'KBB',
        name: 'Motosport'
    },
    automais: {
        pt: 'https:///automais.autosport.pt',
        title: 'Auto Mais',
        name: 'Auto Mais'
    },
    transfer: {
        pt: 'https://transfer.sapo.pt',
        mz: 'https://transfer.sapo.mz',
        ao: 'https://transfer.sapo.ao',
        cv: 'https://transfer.sapo.cv',
        title: 'Transfer',
        name: 'SAPO Transfer'
    },
    ticketline: {
        pt: 'https://ticketline.sapo.pt',
        title: 'Ticketline',
        name: 'Ticketline'
    },
    blueticket: {
        pt: 'https://blueticket.sapo.pt',
        title: 'Blueticket',
        name: 'Blueticket'
    },
    fpa: {
        pt: 'http://portal.fpa.pt',
        title: 'FPA',
        name: 'FP Andebol'
    },
    angola: {
        pt: 'https://sapo.ao',
        title: 'SAPO Angola',
        name: 'SAPO Angola'
    },
    mocambique: {
        pt: 'https://sapo.mz',
        title: 'SAPO Moçambique',
        name: 'SAPO Moçambique'
    },
    caboverde: {
        pt: 'https://sapo.cv',
        title: 'SAPO Cabo Verde',
        name: 'SAPO Cabo Verde'
    },
    timor: {
        pt: 'https://sapo.tl',
        title: 'SAPO Timor',
        name: 'SAPO Timor'
    },
    miranda: {
        pt: 'https://miranda.sapo.pt',
        title: 'Miranda',
        name: 'Miranda by SAPO'
    },
    foreveryoung: {
        pt: 'https://foreveryoung.sapo.pt',
        title: 'Forever Young',
        name: 'Forever Young'
    },
    abcsapoapp: {
        pt: 'https://sobre.sapo.pt/mundo-sapo#apps',
        title: 'ABC SAPO',
        name: 'ABC SAPO'
    },
    sapoapp: {
        pt: 'https://sobre.sapo.pt/mundo-sapo#apps',
        title: 'SAPO',
        name: 'SAPO'
    },
    sapo24app: {
        pt: 'https://sobre.sapo.pt/mundo-sapo#apps',
        title: 'SAPO 24',
        name: 'SAPO 24'
    },
    cinemaapp: {
        pt: 'https://sobre.sapo.pt/mundo-sapo#apps',
        title: 'SAPO Cinema',
        name: 'SAPO Cinema'
    },
    desportoapp: {
        pt: 'https://sobre.sapo.pt/mundo-sapo#apps',
        title: 'SAPO Desporto',
        name: 'SAPO Desporto'
    },
    jornaisapp: {
        pt: 'https://sobre.sapo.pt/mundo-sapo#apps',
        title: 'SAPO Jornais',
        name: 'SAPO Jornais'
    },
    promosapp: {
        pt: 'https://sobre.sapo.pt/mundo-sapo#apps',
        title: 'SAPO Promos',
        name: 'SAPO Promos'
    },
    saboresapp: {
        pt: 'https://sobre.sapo.pt/mundo-sapo#apps',
        title: 'SAPO Sabores',
        name: 'SAPO Sabores'
    },
    tempoapp: {
        pt: 'https://sobre.sapo.pt/mundo-sapo#apps',
        title: 'SAPO Tempo',
        name: 'SAPO Tempo'
    },
    visao: {
        pt: 'https://visao.sapo.pt/',
        title: 'Visão',
        name: 'Visão'
    },
    viral: {
        pt: 'https://viralcheck.sapo.pt/',
        title: 'VIRAL',
        name: 'VIRAL',
        // new: { pt: true }
    },
    estrelasouricos: {
        pt: 'http://estrelaseouricos.sapo.pt',
        title: 'Estrelas e Ouriços',
        name: 'Estrelas e Ouriços'
    },
    prima: {
        pt: 'http://prima.sapo.pt',
        title: 'PRIMA',
        name: 'PRIMA'
    },
    holofote: {
        pt: 'https://holofote.sapo.pt',
        title: 'Holofote',
        name: 'Holofote'
    },
    pme_magazine: {
        pt: 'https://pmemagazine.sapo.pt',
        title: 'PME Magazine',
        name: 'PME Magazine'
    },
    viagensresorts: {
        pt: 'https://foreveryoung.sapo.pt/categoria/viagensresorts',
        title: 'Viagens & Resorts',
        name: 'Viagens & Resorts'
    },
    humanresources: {
        pt: ' https://hrportugal.sapo.pt',
        title: 'Human Resources',
        name: 'Human Resources'
    },
    marketeer: {
        pt: 'https://marketeer.sapo.pt',
        title: 'Marketeer',
        name: 'Marketeer'
    },
    risco: {
        pt: 'https://executivedigest.sapo.pt/revista-risco',
        title: 'Risco',
        name: 'Risco'
    },
    executivedigest: {
        pt: 'https://executivedigest.sapo.pt',
        title: 'Executive Digest',
        name: 'Executive Digest'
    },
    automonitor: {
        pt: 'https://executivedigest.sapo.pt/categoria/motores/',
        title: 'Automonitor',
        name: 'Automonitor'
    },
    magg: {
        pt: 'https://magg.sapo.pt',
        title: 'MAGG',
        name: 'MAGG'
    },
    maemequer: {
        pt: 'https://maemequer.sapo.pt',
        title: 'Mãe-Me-Quer',
        name: 'Mãe-Me-Quer',
        //new: { pt: true }
    },
    voz: {
        pt: 'https://www.sapo.pt/noticias/regioes',
        title: 'VOZ',
        name: 'SAPO Voz',
        //new: { pt: true }
    },
    feriados: {
        pt: 'https://feriadospontesferias.sapo.pt',
        title: 'Feriados',
        name: 'Feriados',
        //new: { pt: true }
    },
    kidsmarketeer: {
        pt: 'https://marketeer.sapo.pt/kidsmarketeer',
        title: 'Kids Marketeer',
        name: 'Kids Marketeer',
        //new: { pt: true }
    },
    lider: {
        pt: 'https://lidermagazine.sapo.pt',
        title: 'Líder',
        name: 'Líder',
        //new: { pt: true }
    },
    passatempos: {
        pt: 'https://www.sapo.pt/passatempos',
        title: 'Passatempos',
        name: 'Passatempos',
        //new: { pt: true }
    }


};
