import * as Util from '../../util.js';
import { menuClasses } from '../../classes-ids.config';

export default class Mail {
    constructor(bsu) {
        this.bsuWidget = bsu;
        this.$mailIcon = Util.getById(menuClasses.userMenu.mailIconId);
        this.$mailPopup = Util.getById(menuClasses.userMenu.mailIframeId);
        //this.iframeSrc = `${this.bsuWidget._config.mailEndpoint}/mail/homeWidget.php?ssoAuth&host=www.sapo.pt&source=bsu`;
        this.simpleIframeSrc = `${this.bsuWidget._config.mailEndpoint}/mail/widget.php?ssoAuth&host=www.sapo.pt&source=bsu`;
        this.iframeSrc = this.simpleIframeSrc;
        this.iframeId = 'mailIframe';
        this.failedAuthentication = false; //para ser usado nos Unit Tests uma vez que o window.location.href manca-se com o JSDOM
    }

    init() {
        return Promise.resolve()
            .then(() => attachEventHandlers.call(this))
            .then(() => (Util.isBelowIE9() && this.bsuWidget.authStartup) || this.bsuWidget.authStartup.Email || this.bsuWidget.authStartup.LoggedIn ? createMailIframe.call(this, this.simpleIframeSrc) : null)
            .catch((e) => console.warn(`Erro no Mail : ${e}`));
    }
}

function createMailIframe(src) {

    let iframe = Util.getById(this.iframeId);
    if (!iframe) {
        Util.createAndAttachIframe({
            id: this.iframeId,
            src: src,
            width: 0,
            height: 0,
            frameBorder: 0,
            border: 0
        }, this.$mailPopup);
    }


}


function attachEventHandlers() {
    Util.addEvent(window, 'message', (e) => iframeEventHandler.call(this, e));
    Util.addEvent(this.$mailIcon, 'click', mailIconCallback.bind(this));

    this.$mailIcon.setAttribute('href', `${this.bsuWidget._config.loginEndpoint}/Login.do?to=${encodeURIComponent(this.bsuWidget._dataOptions.loginUrl)}`);

    Util.addEvent(document, 'click', (e) => {
        if (!e.target.closest(`#${menuClasses.userMenu.mailAreaId}`))
            this.$mailPopup.removeClass(menuClasses.userMenu.mailPopupOpenClass);
    });
}


function iframeEventHandler(evt) {
    var origin = evt.origin, data = evt.data;
    if (this.bsuWidget._config.mailEndpoint.indexOf(origin.split('://')[1]) > -1) {
        var test = '';
        if (typeof data === 'string') {
            data = data.split('|');
            test = data[0];
        } else if (typeof data === 'object') {
            test = data.logoutURL ? 'logoutURL' : data.loginURL ? 'loginURL' : '';
        }
        switch (test) {
            case 'connected':
                break;
            case 'updateCounter':
                let mailCounter = Util.getById(menuClasses.userMenu.mailCounterId);
                if (data[1] > 0) {
                    this.$mailIcon.addClass('bsu-v3-icon-mail--has-email');
                    mailCounter.innerHTML = data[1] > 99 ? '99+' : data[1];
                } else {
                    this.$mailIcon.removeClass('bsu-v3-icon-mail--has-email');
                    mailCounter.innerHTML = '';
                }
                break;
            case 'NoSession':
                break;
            case 'logoutURL':
                Util.getById(menuClasses.userMenu.logoutId).setAttribute('href', `${data.logoutURL}&bsu_redirect=${encodeURIComponent(this.bsuWidget._dataOptions.logoutUrl)}`);
                break;
            // case 'loginURL':
            //     Util.getById(menuClasses.userMenu.myEmailId).setAttribute('href', `${data.loginURL}&source=bsu_login`);
            //     break;
            // case 'sapoMailAccount':
            //data[1] -> true ou false em string
            // Util.getById(menuClasses.userMenu.myEmailId).setAttribute('href', `${data.loginURL}&source=bsu_login`);
            //  break;

        }
    }
}

function mailIconCallback(e) {
    e.preventDefault();
    var iframe = Util.getById(this.iframeId);

    if (this.$mailPopup) {
        if (this.$mailPopup.hasClass(menuClasses.userMenu.mailPopupOpenClass)) {
            this.$mailPopup.removeClass(menuClasses.userMenu.mailPopupOpenClass);
        } else {
            iframe && iframe.contentWindow.postMessage('bsuMailButtonClicked', '*');
            if (!Util.isBelowIE9()) {
                Util.getUserInfo().then(modernCallback.bind(this));
            } else {
                Util.isLogged().then(ieCallback.bind(this));
            }
        }
    }
}

function modernCallback(userInfo) {
    //console.log(userInfo)
    if (userInfo.Email || userInfo.LoggedIn) {
        Util.createEvent('mailOpen', userInfo);
        this.$mailPopup.addClass(menuClasses.userMenu.mailPopupOpenClass);
        createMailIframe.call(this, this.iframeSrc);
    }
    else {
        failedAuthentication.call(this);
    }
}

function ieCallback(auth) {
    if (!auth) {
        failedAuthentication.call(this);
    }
    else {
        Util.createEvent('mailOpen', true);
        this.$mailPopup.addClass(menuClasses.userMenu.mailPopupOpenClass);
        createMailIframe.call(this, this.iframeSrc);
    }
}

function failedAuthentication() {
    this.failedAuthentication = true;
    window.location.href = this.$mailIcon.getAttribute('href');
}