"use strict";

import * as polyfills from "./polyfills.js";
import * as cssStyle from "./styles.js"; //é criado e apagado na geração do bundle
import * as Util from "./util.js";
import * as config from "./config.js";
import Cookies from 'js-cookie';

import AppsStores from "./modules/apps-stores";
import EventHandlers from "./modules/event-handlers";
import Mail from "./modules/mail";
// import AddToHomeScreen from './modules/add-homescreen';
import Login from "./modules/login";
import PushAndNewsletter from "./modules/push-newsletter";
import SocialNetworks from "./modules/social-networks";
import IABCmp from "./modules/cmp";

import bsuHTML from "../compiled-views/bsu.html.js";

import Menu from "./menus/";
import Footer from "./footer/";
import { menuClasses } from "./classes-ids.config.js";

const BSUV4_exception_domains = [
    'jornaldeabrantes.sapo.pt', //ícones
    'informedia.sapo.pt', //encoding

    'lidermagazine.sapo.pt', //precisam alterações sites
    'pmemagazine.sapo.pt', //precisam alterações sites
    'viral.sapo.pt', //precisam alterações sites
    'portocanal.sapo.pt', //implementação incorrecta
];

export default class BSU {
    constructor() {
        // console.log(config.env)
        this.$container = null;
        this.$script = this.getScriptTag();
        this.$footerLinksContainer = Util.getById(
            menuClasses.footer.footerLinksId
        );
        this.$footerContainer = Util.getById(
            menuClasses.footer.footerContainerId
        );
        this.$bannersContainer = null;
        this.isShowingBanner = false;

        this._version = "1.0.0";

        this._doodle = {
            drawn: false
        }

        this._config = {
            protocol:
                window.location.protocol === "https:" ? "https:" : "http:",
            hostname: window.location.hostname,
            host: window.location.host,
            originUrl: window.location.origin,
            appAndNotificationsCallBack: "BSU2JsonPAppBannersAndNotifications",
            searchUrl: "https://www.sapo.pt/pesquisa?q=",
            loginURL: '#{LOGINURL}',
            loginEndpoint: '#{LOGINENDPOINT}',
            iframePath: "/Bsu/Min.do",
            mailEndpoint: '#{MAILENDPOINT}',
            idEndPoint: '#{IDENDPOINT}',
            apiHost: '#{APIENDPOINT}',
            apiDataEndpoint: '#{APIDATA}',
        };

        this._paths = {
            assets: '#{ASSETS}',
            appsAndNotifications: '#{APPSANDNOTIFICATIONS}',
        };

        this._dataOptions = {
            target: null,
            country: "pt",
            // width: null,
            loginUrl: null,
            logoutUrl: null,
            lang: null,
            noFooter: false,
            newScript: null,
            newStyle: null,
            bgColor: null,
            mainColor: null,
            theme: null,
            appStoreContainers: null,
            partner: null,
            pushNotification: null,
            socialLinksOverride: {
                facebook: "",
                twitter: "",
                instagram: ""
            },
            modules: null
        };

        this._analytics = {
            _confHashAnalyticsLogo:
                `?utm_source=bsu&utm_medium=web&utm_campaign=bsu_logo&utm_content=${window.location.hostname}`,
            _confHashAnalyticsLargeMenu:
                `?utm_source=bsu&utm_medium=web&utm_campaign=bsu_mainmenu&utm_content=${window.location.hostname}`,
            _confHashAnalyticsMoreMenu:
                `?utm_source=bsu&utm_medium=web&utm_campaign=bsu_moremenu&utm_content=${window.location.hostname}`,
            _confHashFooterAnalytics:
                `?utm_source=bsu&utm_medium=web&utm_campaign=bsu_footer&utm_content=${window.location.hostname}`,
            _confHashLoginAreaAnalytics:
                `?utm_source=bsu&utm_medium=web&utm_campaign=bsu_loginarea&utm_content=${window.location.hostname}`,
        };

        this.authStartup;
    }

    updateToBSUV4() {
        if (BSUV4_exception_domains.indexOf(window.location.host) < 0) {
            var bsuScript = document.createElement('script');
            var currBSU = document.getElementById('bsuScript');
            if (!currBSU) {
                currBSU = document.getElementById('sapo-bsu-js');
            }
            if (currBSU) {
                Array.from(currBSU.attributes).forEach((at) => {
                    bsuScript.setAttribute(at.name, at.value);
                })
                bsuScript.id = 'bsuScript';
                bsuScript.src = 'https://js.sapo.pt/Projects/bsuv4/js/bsuv4.min.js';
                document.body.appendChild(bsuScript);
                currBSU.remove();
                return true;
            }
        }
        return false;
    }

    init() {
        if (this.updateToBSUV4())
            return;

        this._dataOptions = Object.assign(
            {},
            this._dataOptions,
            this.getDataOptions()
        );

        return this.buildHTML()
            .then(() => this.loadNewStyles())
            .then(() => this.getDoodle())
            .then(() => this._dataOptions.partner && this.buildPartner())
            .then(
                () =>
                    !this._dataOptions.noFooter &&
                    !this._dataOptions.partner &&
                    this.buildFooter()
            )
            .then(() => this.loadNewScript())
            .then(() => this.runModules())
            .then(() => this.runCMP())
            .then(() => this.exposeFunctions())
            .then(() => Util.deleteLegacyCookies());

    }

    getScriptTag() {
        let $scriptById = Util.getById("bsuScript");
        if ($scriptById) return $scriptById;
        else {
            let scriptTags = Util.getAllElements("script");

            scriptTags = Array.prototype.slice.call(scriptTags);

            scriptTags.forEach(scriptTag => {
                let srcAttr = scriptTag.getAttribute("src");
                if (
                    srcAttr &&
                    (srcAttr.indexOf("bsuv3/js/bsuv3") > -1 ||
                        srcAttr.indexOf("bsu_v2.0/barra.js") > -1)
                ) {
                    $scriptById = scriptTag;
                }
            });
        }
        if (!$scriptById) throw new Error(`No bsuv3 script tag`);
        return $scriptById;
    }

    hasBsuV2ScriptTag() {
        let scriptTags = Util.getAllElements("script"),
            hasScript = false;

        scriptTags = Array.prototype.slice.call(scriptTags);

        scriptTags.forEach(scriptTag => {
            let srcAttr = scriptTag.getAttribute("src");
            if (srcAttr && srcAttr.indexOf("bsu_v2.0/barra.js") > -1) {
                hasScript = true;
            }
        });
        return hasScript;
    }

    buildIfOldBSU() {
        this.$container.addClass("bsu-v2");

        let $bsuRoot = Util.getById("bsu_root"),
            $bsuPlaceholder = document.createElement("div");

        $bsuPlaceholder.setAttribute("id", menuClasses.placeholderId);
        $bsuRoot && $bsuRoot.parentElement.removeChild($bsuRoot);
        document.body.insertBefore($bsuPlaceholder, null);
    }

    loadNewStyles() {
        if (this._dataOptions.newStyle) {
            let $stylesheet = document.createElement("style");
            $stylesheet.id = menuClasses.stylesheets.main;
            $stylesheet.setAttribute("href", this._dataOptions.newStyle);
            $stylesheet.setAttribute("rel", "stylesheet");
            document.head.appendChild($stylesheet);
        }
    }

    loadNewScript() {
        if (this._dataOptions.newScript) {
            let $script = document.createElement("script");
            $script.setAttribute("src", this._dataOptions.newScript);
            $script.id = menuClasses.scripts.newScript;
            document.head.appendChild($script);
        }
    }

    buildHTML() {
        return Promise.resolve()
            .then(() =>
                Util.appendFromTemplate(
                    this._dataOptions.target,
                    bsuHTML,
                    "afterbegin"
                )
            )
            .then(
                () =>
                    (this.$container = Util.getById(menuClasses.bsuContainerId))
            )
            .then(
                () => (this.hasBsuV2ScriptTag() ? this.buildIfOldBSU() : null)
            )
            .then(() => {
                let logo = Util.getById(menuClasses.logoId);
                if (logo) {
                    logo.setAttribute(
                        "href",
                        `https://www.sapo.${this._dataOptions.country}/${this._analytics._confHashAnalyticsLogo
                        }`
                    );
                }
            })
            .then(() => this.userAreaMenu())
            .then(
                () =>
                    this._dataOptions.bgColor &&
                    changeBSUBackgroundColor.call(this)
            )
            .then(
                () =>
                    this._dataOptions.mainColor && changeBSUMainColor.call(this)
            )
            .then(
                () =>
                    this._dataOptions.theme &&
                    this._dataOptions.theme === "dark" &&
                    this.$container.addClass(menuClasses.darkBgClass)
            )
            .then(() => this.buildMenu());
    }

    userAreaMenu() {
        let mailLi$ = Util.getById(menuClasses.userMenu.mailAreaId),
            userLi$ = Util.getById(menuClasses.userMenu.userAreaId);
        if (
            (this._dataOptions.partner &&
                !this._dataOptions.modules.mailModule)
        ) {
            mailLi$.parentNode.removeChild(mailLi$);
        }
        if (
            (this._dataOptions.partner &&
                !this._dataOptions.modules.loginModule)
        ) {
            userLi$.parentNode.removeChild(userLi$);
        }
    }

    buildMenu() {
        let $userMenu = Util.getById(menuClasses.userMenu.id),
            $menuContainer = Util.getById(menuClasses.mainId),
            menu = new Menu(
                this._dataOptions.country,
                this._dataOptions.partner,
                this._analytics
            ),
            $bsuPlaceholder = Util.getById(menuClasses.placeholderId);

        this._dataOptions.showMenu && $menuContainer.insertBefore(menu.build(), $userMenu);
        $menuContainer.addClass(`bsu-v3-${this._dataOptions.country}`);

        if (this._dataOptions.width) {
            $menuContainer.style["maxWidth"] = `${this._dataOptions.width}px`;
            if (this._dataOptions.bgColor && $bsuPlaceholder) {
                $bsuPlaceholder.style[
                    "backgroundColor"
                ] = this._dataOptions.bgColor;
            } else if ($bsuPlaceholder) {
                $bsuPlaceholder.style["backgroundColor"] = "#111";
            }
        }
    }

    buildPartner() {
        this.$container.addClass(
            `bsu-v3-partner bsu-v3-partner--${this._dataOptions.partner} ${menuClasses.darkBgClass
            }`
        );
    }

    buildFooter() {
        let footer = new Footer(
            this.$footerContainer,
            this._dataOptions.country,
            this._analytics._confHashFooterAnalytics,
            this._dataOptions.lang,
            this.$footerLinksContainer
        );
        footer.build();
    }

    runCMP() {
        if (!this._dataOptions.preview) {
            if (navigator.userAgent.indexOf("MSIE") >= 0) {
                Util.createEvent("showFooter", false);
            } else {
                if (Util.validate404Page())
                    return;

                let iabCMP = new IABCmp();
                if (!this._dataOptions.partner ||
                    this._dataOptions.modules.cookiesModule) {
                    iabCMP.inject();
                }
                else {
                    iabCMP.setListenersForPartners();
                }
            }
        }
    }

    getDoodle() {
        let that = this;

        const shouldDoApiRequest = function () {
            let lastApiRequestDate = Util.getLocalStorage('bsu-doodle-request');
            if (lastApiRequestDate) {
                try {
                    return (new Date().getTime() - parseInt(lastApiRequestDate)) > 0;
                } catch (e) {
                    return true;
                }
            }
            return true;
        };

        const parseDoodleData = function (data) {
            let logo = document.querySelector('#bsu-v3-logo'),
                logoSVG = document.querySelector('#bsu-v3-logo svg');

            if (that._dataOptions.partner && data.doodle && data.doodle.partner === 0) return;

            data.doodle = {};

            if (data.Doodles && data.Doodles.length > 0) {
                let doodles = data.Doodles;
                if (typeof doodles === 'object' && !Array.isArray(doodles)) {
                    doodles = [doodles];
                }
                let currDateTime = new Date().getTime();
                data.doodle = doodles.find((p) => {
                    return (
                        //só tem data início
                        (p.startDate && !p.endDate && (parseFloat(p.startDate) < currDateTime))
                        ||
                        //só tem data fim
                        (!p.startDate && p.endDate && (currDateTime < parseFloat(p.endDate)))
                        //tem as duas
                        || (p.startDate && p.endDate && parseFloat(p.startDate) < currDateTime && currDateTime < parseFloat(p.endDate))
                    )
                })
                if (data.doodle) {
                    if (data.doodle.src && data.doodle.src.indexOf('thumbs.web.sapo') < 0) {
                        data.doodle.src = `https://thumbs.web.sapo.io/?pic=${data.doodle.src}&proxy=1`;
                    }
                }
            }

            if (data.doodle) {
                if (data.doodle.title) {
                    logo.setAttribute('title', data.doodle.title);
                }
                if (data.doodle.src) {
                    logo.addClass('faded');
                    setTimeout(() => {
                        logoSVG.style.display = 'none';
                        logo.style.backgroundImage = 'url("' + data.doodle.src + '")';
                        logo.removeClass('faded');
                    }, 500);
                }
            }
        }

        let localDoodleDate = Util.getLocalStorage('bsu-v3-doodle');
        if (!shouldDoApiRequest()) {
            if (localDoodleDate) {
                parseDoodleData(JSON.parse(localDoodleDate));
            }
            return;
        }

        let url = this._config.apiHost + this._config.apiDataEndpoint;
        if (this._dataOptions.preview) {
            url = url + '?prv_dt=' + this._dataOptions.preview;
        }

        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }
                return response.json();
            })
            .then(data => {
                delete data.Promo;

                let nextRequestDate = new Date();
                nextRequestDate.setHours(nextRequestDate.getHours() + 1, 0, 0, 0);
                Util.setLocalStorage('bsu-doodle-request', nextRequestDate.getTime().toString());
                Util.setLocalStorage('bsu-v3-doodle', JSON.stringify(data));

                parseDoodleData(data);
            })
            .catch(error => {
                console.error('Error fetching doodle:', error);
            });
    }

    runModules() {
        let appStores = new AppsStores(this),
            login = new Login(this),
            mail = new Mail(this),
            eventHandlers = new EventHandlers(this),
            pushnewsletter = new PushAndNewsletter(this),
            socialNetworks = new SocialNetworks(this),
            cookiesModule = this._dataOptions.modules.cookies,
            appStoresModule = this._dataOptions.modules.appStores,
            socialNetModule = this._dataOptions.modules.socialNetModule,
            loginModule = this._dataOptions.modules.loginModule,
            mailModule = this._dataOptions.modules.mailModule,
            pushnewsletterModule = this._dataOptions.modules
                .pushnewsletterModule;

        return eventHandlers.init().then(() => {
            if (
                this._dataOptions.partner &&
                !this._dataOptions.modules.cookiesModule
            ) {
                return this.partnersModules(
                    cookiesModule,
                    appStores,
                    appStoresModule,
                    socialNetworks,
                    socialNetModule,
                    pushnewsletter,
                    pushnewsletterModule,
                    login,
                    loginModule,
                    mail,
                    mailModule
                );
            } else {
                if (
                    !this._dataOptions.partner
                ) {
                    this.userModules(mail, login);
                }
                Util.addEvent(document.body, "showFooter", e => {
                    if (this._dataOptions.partner) {
                        return this.partnersModules(
                            cookiesModule,
                            appStores,
                            appStoresModule,
                            socialNetworks,
                            socialNetModule,
                            pushnewsletter,
                            pushnewsletterModule
                        );
                    } else {
                        return this.nonPartnerModules(
                            appStores,
                            socialNetworks,
                            pushnewsletter,
                        );
                    }
                });
            }
        });
    }

    partnersModules(
        cookiesModule,
        appStores,
        appStoresModule,
        socialNetworks,
        socialNetModule,
        pushnewsletter,
        pushnewsletterModule,
        login,
        loginModule,
        mail,
        mailModule
    ) {
        return Promise.resolve()
            .then(() => loginModule ? this.checkInitialLogin() : null)
            .then(() => (mailModule ? mail.init() : Promise.resolve()))
            .then(() => (loginModule ? login.init() : Promise.resolve()))
            .then(() => {
                if (cookiesModule || appStoresModule) {
                    return Util.fetchJSONP(
                        this._paths.appsAndNotifications,
                        this._config.appAndNotificationsCallBack
                    );
                }
            })
            .then(
                data =>
                    pushnewsletterModule
                        ? pushnewsletter.init()
                        : Promise.reject()
                            .catch(
                                () =>
                                    appStoresModule
                                        ? appStores.init(data.apps)
                                        : Promise.reject()
                            )
                            .catch(
                                () =>
                                    socialNetModule
                                        ? socialNetworks.init()
                                        : Promise.reject()
                            )
                            .catch(() =>
                                console.log("finish modules partner")
                            )
            )
            .then(() => this.removeBannerContainerIfEmpty());
        // .catch(err => console.log(`Erro: ${err}`))
    }

    nonPartnerModules(appStores, socialNetworks, pushnewsletter) {
        return Util.fetchJSONP(
            this._paths.appsAndNotifications,
            this._config.appAndNotificationsCallBack
        )
            .then(data =>
                appStores
                    .createAppsPromotionMenu(data.apps)
                    .then(() => pushnewsletter.init())
                    .catch(() => appStores.init(data.apps))
                    .catch(() => socialNetworks.init())
                    .catch(() => console.log("finish modules nonpartner"))
            )
            .then(() => this.removeBannerContainerIfEmpty());
        // .catch(err => console.log(`Erro: ${err}`))
    }

    userModules(mail, login) {
        return this.checkInitialLogin()
            .then(() => mail.init())
            .then(() => login.init())
            .then(() => Util.createEvent("resize"));
    }

    checkInitialLogin() {
        if (!Util.isBelowIE9()) {
            return Util.getUserInfo().then(
                (userInfo) => {
                    this.authStartup = userInfo;
                    this.sendUserDataToDataLayer(userInfo.LoggedIn);
                });
        } else {
            return Util.isLogged().then((userInfo) => {
                this.authStartup = userInfo;
                this.sendUserDataToDataLayer(userInfo.LoggedIn);
            });
        };
    }

    sendUserDataToDataLayer(loggedIn) {
        let dataLayerInterval;
        dataLayerInterval = setInterval(() => {
            if (window.dataLayer) {
                window.dataLayer.push({ user_LoggedIn: loggedIn });
                clearInterval(dataLayerInterval);
            }
        }, 100);
    }

    getDataOptions() {
        let logoutAttr = this.$script.getAttribute("data-logout-url"),
            loginAttr = this.$script.getAttribute("data-login-url"),
            countryAttr = this.$script.getAttribute("data-country"),
            targetAttr =
                this.$script.getAttribute("data-target") ||
                `#${menuClasses.placeholderId}`,
            noFooterAttr = this.$script.getAttribute("data-nofooter"),
            themeAttr = this.$script.getAttribute("data-theme"),
            widthAttr = this.$script.getAttribute("data-width")
                ? this.$script.getAttribute("data-width").replace("px", "")
                : null;
        let $targetElm = Util.getElement(targetAttr);

        return {
            target: $targetElm ? $targetElm : document.body,
            country: countryAttr ? countryAttr : "pt",
            lang: this.$script.getAttribute("data-lang"),
            showMenu: this.$script.getAttribute("data-menu") !== "false",
            width:
                widthAttr && parseInt(widthAttr) < 960
                    ? 960
                    : widthAttr
                        ? widthAttr
                        : "",
            loginUrl: loginAttr
                ? loginAttr.toLocaleLowerCase()
                : window.location.href,
            logoutUrl: logoutAttr
                ? logoutAttr
                : window.location.href,
            noFooter: noFooterAttr ? noFooterAttr.toLocaleLowerCase() : null,
            newStyle: this.$script.getAttribute("data-style-url"),
            newScript: this.$script.getAttribute("data-script-url"),
            bgColor: this.$script.getAttribute("data-bg-color"),
            mainColor: this.$script.getAttribute("data-main-color"),
            theme: themeAttr ? themeAttr.toLocaleLowerCase() : "light",
            appStoreContainers: this.$script.getAttribute(
                "data-appstore-containers"
            ),
            partner: this.$script.getAttribute("data-partner"),
            pushntfsSelector: this.$script.getAttribute(
                "data-pushntfs-selector"
            ),
            newsletterSelector: this.$script.getAttribute(
                "data-newsletter-selector"
            ),
            socialLinks: {
                facebook: this.$script.getAttribute("data-social-facebook"),
                twitter: this.$script.getAttribute("data-social-twitter"),
                instagram: this.$script.getAttribute("data-social-instagram")
            },
            modules: this.getModulesAttributes(
                this.$script.getAttribute("data-modules")
            ),
            preview: this.$script.getAttribute("data-preview")
        };
    }

    getModulesAttributes(attributes) {
        if (attributes) {
            return attributes.split("|").reduce((obj, attr) => {
                if (attr === "cookies")
                    return Object.assign(obj, { cookiesModule: true });
                else if (attr === "socialNetworks")
                    return Object.assign(obj, { socialNetModule: true });
                else if (attr === "appStores")
                    return Object.assign(obj, { appStoresModule: true });
                else if (attr === "pushnewsletter")
                    return Object.assign(obj, { pushnewsletterModule: true });
                else if (attr === "login")
                    return Object.assign(obj, { loginModule: true });
                else if (attr === "mail")
                    return Object.assign(obj, { mailModule: true });
            }, {});
        }
        return {};
    }

    createBannersContainer() {
        if (!this.$bannersContainer) {
            this.$bannersContainer = Util.createElement({
                type: "ul",
                className: menuClasses.bannerContainer
            });
            this.$container.appendChild(this.$bannersContainer);
        }
    }

    showBannersContainer() {
        this.isShowingBanner = true;
        if (this.$bannersContainer.childElementCount) {
            this.$bannersContainer.addClass(`bsu-v3-ntfs-show`);
        }
    }

    removeBannerContainerIfEmpty() {
        if (
            this.$bannersContainer &&
            this.$bannersContainer.parentNode &&
            !this.$bannersContainer.childElementCount
        ) {
            this.$bannersContainer.parentNode.removeChild(
                this.$bannersContainer
            );
            this.isShowingBanner = false;
        }
    }

    exposeFunctions() {
        window.SAPO = window.SAPO || {};
        window.SAPO.BSU = window.SAPO.BSU || (function () {

            let resetLogoColor = () => {
                Util.getElement("#bsu-logoSegment1").style.fill = "#69A618";
                Util.getElement("#bsu-logoSegment2").style.fill = "#7AB800";
                Util.getElement("#bsu-logoSegment3").style.fill = "#69A618";
                Util.getElement("#bsu-logoSegment4").style.fill = "#7AB800";
                Util.getElement("#bsu-logoSegment5").style.fill = "#5B8F22";
            },
                LightenColor = (color, percent) => {
                    let num = parseInt(color, 16),
                        amt = Math.round(2.55 * percent),
                        R = (num >> 16) + amt,
                        B = (num >> 8 & 0x00FF) + amt,
                        G = (num & 0x0000FF) + amt;

                    return (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100 + (G < 255 ? G < 1 ? 0 : G : 255)).toString(16).slice(1);
                },
                setLogoColor = (hexColor) => {
                    let strippedColor = hexColor.substr(1);
                    Util.getElement("#bsu-logoSegment1").style.fill = "#" + LightenColor(strippedColor, -7);
                    Util.getElement("#bsu-logoSegment2").style.fill = "#" + LightenColor(strippedColor, 0);
                    Util.getElement("#bsu-logoSegment3").style.fill = "#" + LightenColor(strippedColor, -7);
                    Util.getElement("#bsu-logoSegment4").style.fill = "#" + LightenColor(strippedColor, 0);
                    Util.getElement("#bsu-logoSegment5").style.fill = "#" + LightenColor(strippedColor, -11);
                },

                delayedChange = (elementId, hexColor, delay) => {
                    setTimeout(function () {
                        Util.getElement("#" + elementId).style.fill = "#" + hexColor;
                    }, delay);
                },
                rotateLogoColor = (hexColor, rotationDelay) => {

                    let strippedColor = hexColor.substr(1);
                    let delay = rotationDelay || 100;

                    delayedChange("bsu-logoSegment1", LightenColor(strippedColor, -10), delay * 2);
                    delayedChange("bsu-logoSegment2", LightenColor(strippedColor, 0), delay * 3);
                    delayedChange("bsu-logoSegment3", LightenColor(strippedColor, -10), delay * 4);
                    delayedChange("bsu-logoSegment4", LightenColor(strippedColor, 0), delay * 5);
                    delayedChange("bsu-logoSegment5", LightenColor(strippedColor, -20), delay * 6);
                    delayedChange("bsu-logoSegment1", "69A618", delay * 7);
                    delayedChange("bsu-logoSegment2", "7AB800", delay * 8);
                    delayedChange("bsu-logoSegment3", "69A618", delay * 9);
                    delayedChange("bsu-logoSegment4", "7AB800", delay * 10);
                    delayedChange("bsu-logoSegment5", "5B8F22", delay * 11);

                };

            return {
                resetLogoColor: resetLogoColor,
                setLogoColor: setLogoColor,
                rotateLogoColor: rotateLogoColor
            };
        })();
    }
}

function changeBSUBackgroundColor() {
    let $stylesheet = document.createElement("style");
    $stylesheet.id = menuClasses.stylesheets.backgroundColor;
    $stylesheet.innerHTML = `
    #bsu-v3-ctn #bsu-v3,
    #bsu-v3-ctn.${menuClasses.darkBgClass} #bsu-v3,
    #bsu-v3-ctn #bsu-v3 .bsu-v3-menu>li.bsu-v3-has-submenu ul.bsu-v3-submenu,
    #bsu-v3-ctn #bsu-v3 .bsu-v3-menu>li.bsu-v3-more div.bsu-v3-give-it-to-me-baby,
    #bsu-v3-ctn.${menuClasses.darkBgClass
        } #bsu-v3 .bsu-v3-menu>li.bsu-v3-more div.bsu-v3-give-it-to-me-baby,
    #bsu-v3-ctn .bsu-v3-menu.bsu-v3-menu-services-menu>li.bsu-v3-login-ctn ul.bsu-v3-submenu:before {background-color:${this._dataOptions.bgColor
        };}
    `;
    document.head.appendChild($stylesheet);
}

function changeBSUMainColor() {
    let $stylesheet = document.createElement("style");
    $stylesheet.id = menuClasses.stylesheets.mainColor;
    $stylesheet.innerHTML = `
    #bsu-v3-ctn .bsu-v3-menu>li a:hover,
    #bsu-v3-ctn.${menuClasses.darkBgClass} #bsu-v3 .bsu-v3-menu>li a:hover,
    #bsu-v3-ctn .bsu-v3-menu>li.bsu-v3-has-submenu:hover > a,
    #bsu-v3-ctn .bsu-v3-menu>li.bsu-v3-has-submenu.bsu-v3-has-submenu--open-submenu > a,
    #bsu-v3-ctn .bsu-v3-ntfs li .bsu-v3-ntf a { color:${this._dataOptions.mainColor
        };}

    #bsu-v3-ctn .bsu-v3-menu>li.bsu-v3-has-submenu>a:hover:after,
    #bsu-v3-ctn .bsu-v3-menu>li.bsu-v3-has-submenu:hover>a:after,
    #bsu-v3-ctn.${menuClasses.darkBgClass
        } #bsu-v3 .bsu-v3-menu>li.bsu-v3-has-submenu>a:hover:after,
    #bsu-v3-ctn.${menuClasses.darkBgClass
        } #bsu-v3 .bsu-v3-menu>li.bsu-v3-has-submenu:hover>a:after,
    #bsu-v3-ctn .bsu-v3-menu>li.bsu-v3-has-submenu.bsu-v3-has-submenu--open-submenu>a:after { border-color:${this._dataOptions.mainColor
        };}
    `;
    document.head.appendChild($stylesheet);
}
