module.exports = {
    pt: [
        'mail',
        'jornais',
        'auto',
        'casa',
        'emprego',
        'blogs',
        'promos',
        'sep'
    ],
    ao: [
        'sapo24',
        'desporto',
        'lifestyle',
        'mag',
        'viagens',
        'tek',
        'jornais',
        'promos',
        'sep',
        'auto',
        'casa',
        'emprego',
        'sep',
        'sep',
        'mail',
        'blogs',
        'tempo',
        'sep',
    ],
    mz: [
        'mail',
        'blogs',
        'tempo',
        'farmacias',
        'sep',
        'sapo24',
        'desporto',
        'lifestyle',
        'mag',
        'viagens',
        'tek',
        'jornais',
        'promos',
        'sep',
        'auto',
        'casa',
        'emprego',
        'sep',
        'sep'
    ],
    cv: [
        'noticias',
        'desporto',
        'lifestyle',
        'muzika',
        'viagens',
        'jornais',
        'sep',
        'auto',
        'casa',
        'emprego',
        'sep',
        'mail',
        'blogs',
        'tempo',
        'sep',
    ],
    tl: [
        'mail'
    ]
}
