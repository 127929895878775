import * as Util from '../util.js';
import * as menuEntries from './menu-entries.config';
import { menuClasses } from  '../classes-ids.config.js';
import * as menuLarge from './menu-large.config';
import moreMenu from './menu-more.config';

export default class Menu {
    constructor(country, partner, analytics) {
        this.partner = partner;
        this.country = country;
        this.analytics = analytics;
        this.largeMenu = new LargeMenu();
        this.moreMenu = new MoreMenu();
    }

    build() {
        let largeMenu = this.largeMenu.build(this.country, this.partner, this.analytics._confHashAnalyticsLargeMenu),
            moreMenu = this.moreMenu.build(this.country, this.analytics._confHashAnalyticsMoreMenu),
            $ul = Util.createElement({ id: menuClasses.mainUl.id, type: 'ul', className: menuClasses.mainUl.classes });

        $ul.innerHTML = `${largeMenu}${moreMenu}`;
        $ul.setAttribute('display', 'none');
        return $ul;
    }
}

class LargeMenu {

    constructor() { }

    build(country, partner, analytics) {
        return getMenuLarge(country, partner).reduce((menu, entry) => {
            try{
                let $li = Util.createElement({ type: 'li' }),
                    $a = Util.createElement({ type: 'a', innerHTML: menuEntries[entry].title });
                $a.setAttribute('href', getCountryEntry(entry, country, analytics));

                if('disruptiveImage' in menuEntries[entry]){
                    $a.setAttribute('data-disrupt', entry);
                }

                if (entry === 'sep') {
                    $li.innerHTML = '<span>&bull;</span>';
                    $li.className = menuClasses.largeMenu.separator;
                } else {
                    $li.appendChild($a);
                    $li.className = menuClasses.largeMenu.li;
                    if (menuEntries[entry].new && menuEntries[entry].new[country]) $li.className += ` ${menuClasses.largeMenu.newEntry}`;
                }

                menu += $li.outerHTML;
            }
            catch(e){}
            return menu;
        }, '');
    }
}

class MoreMenu {

    constructor() { }

    build(country, analytics) {
        let $li = Util.createElement({ type: 'li', className: menuClasses.moreMenu.li }),
            $a = Util.createElement({ type: 'a' }),
            $span = Util.createElement({ type: 'span', className: menuClasses.moreMenu.span, innerHTML: 'Tudo' }),
            $div = Util.createElement({ type: 'div', className: menuClasses.moreMenu.div }),
            $ul = Util.createElement({ type: 'ul', className: menuClasses.moreMenu.ul, id: menuClasses.moreMenu.ulId });

        $ul.innerHTML = moreMenu[country].reduce((menu, entry) => {
            let $section = Util.createElement({ type: 'div', className: menuClasses.moreMenu.section }),
                $subLi = Util.createElement({ type: 'li' }),
                $h6 = Util.createElement({ type: 'h6', className: menuClasses.moreMenu.h6, innerHTML: entry.title }),
                $subUl = Util.createElement({ type: 'ul', className: menuClasses.moreMenu.subUl });

            $subUl.innerHTML = entry.submenus.reduce((submenu, subentry) => {
                try{
                    let className;
                    if (menuEntries[subentry].new && menuEntries[subentry].new[country]) {
                        className = `${menuClasses.largeMenu.newEntry}`;
                    }
                    submenu += Util.createMenuLi(menuEntries[subentry].name, getCountryEntry(subentry, country, analytics), className);

                }
                catch(e){ }
                return submenu;
            }, '');

            $section.appendChild($h6);
            $section.appendChild($subUl);
            $subLi.appendChild($section);
            menu += $subLi.outerHTML;
            return menu;
        }, '');

        $div.appendChild($ul);
        $a.appendChild($span);
        $li.appendChild($a);
        $li.appendChild($div);
        return $li.outerHTML;
    }
}

function getCountryEntry(menuentry, country, analytics) {
    let entry = (menuEntries[menuentry][country] ? menuEntries[menuentry][country] : menuEntries[menuentry]['pt']),
        hash = entry.split('#');

    return hash[0] +
        (hash[0].indexOf('?') < 0 ? analytics : analytics.replace('?', '&')) +
        (hash[1] ? '#' + hash[1] : '');
}

function getMenuLarge(country, partner) {
    return menuLarge[`${country}_${partner}`] ? menuLarge[`${country}_${partner}`] : menuLarge[country];
}
