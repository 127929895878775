module.exports = {
    about: {
        text: 'Sobre o SAPO',
        href: 'https://sobre.sapo.pt',
        liclass: '',
        liid: '',
        lang: { tet_TL: 'Kona-b\u00e1', en_US: 'About SAPO' },
    },
    terms: {
        text: 'Condi\u00e7\u00f5es de Utiliza\u00e7\u00e3o',
        href: 'https://ajuda.sapo.pt/pt-pt/security/condicoes-de-utilizacao',
        liclass: '',
        liid: '',
        lang: { tet_TL: 'Kondisaun Utilizasaun', en_US: 'Terms of Service' },
    },
    privacy: {
        text: 'Pol\u00edtica de Privacidade',
        href: 'https://ajuda.sapo.pt/pt-pt/security/politica-de-privacidade',
        liclass: '',
        liid: '',
        lang: { tet_TL: 'Pol\u00edtika Privasidade', en_US: 'Privacy Policy' },
    },
    rgpd: { text: 'RGPD', href: 'https://www.telecom.pt/pt-pt/Paginas/RGPD.aspx' },
    cookies: {
        text: 'Sobre Cookies',
        href: 'https://ajuda.sapo.pt/pt-pt/security/politica-de-cookies/o-que-sao-cookies',
        liclass: '',
        liid: '',
        lang: { tet_TL: 'Kona-b\u00e1 Cookie', en_US: 'About Cookies' },
    },
    help: {
        text: 'Ajuda',
        href: 'https://ajuda.sapo.pt?ref=' + encodeURIComponent(window.location.hostname),
        liclass: '',
        liid: '',
        lang: { tet_TL: 'Tulun', en_US: 'Help' },
    },
    cmp: {
        text: 'Defini\u00e7\u00f5es de Privacidade',
        href: 'javascript:window.__tcfapi("displayConsentUi", 2, function() {} ); ',
        liclass: '',
        liid: '',
        lang: { tet_TL: 'Definisaun Privasidade', en_US: 'Privacy Settings' },
    },
};
