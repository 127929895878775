module.exports = {
    pt: [
        {
            title: 'Notícias',
            submenus: [
                'automonitor',
                'eco',
                'executivedigest',
                'fpa',
                'humanresources',
                'jornali',
                'lider',
                'marketeer',
                'pme_magazine',
                'poligrafo',
                'portocanal',
                'pplware',
                'risco',
                'sapo24',
                'desporto',
                'jornais',
                'tek',
                'videos',
                'voz',
                'sol',
                'viral'
            ]
        },
        {
            title: 'Vida e Lazer',
            submenus: [
                'estrelasouricos',
                'foreveryoung',
                'greensavers',
                'kidsmarketeer',
                'miranda',
                'magg',
                'maemequer',
                'mood',
                'passatempos',
                'prima',
                'rockinrio',
                'lifestyle',
                'mag',
                'viagens',
                'superbrands',
                'viagensresorts'
            ]
        },
        {
            title: 'Serviços',
            submenus: [
                'feriados',
                'blogs',
                'farmacias',
                'mail',
                'tempo',
                'transfer'
            ]
        },
        {
            title: 'Classificados',
            submenus: [
                'auto',
                'autobarcos',
                'automotos',
                'autopremium',
                'autovendaja',
                'casa',
                'emprego'
                // 'kbb',
            ]
        },
        {
            title: 'E-commerce',
            submenus: [
                'promos'
                // 'ticketline',
                // 'blueticket'
            ]
        },
        {
            title: 'Apps',
            submenus: [
                'sapoapp',
                'sapo24app',
                'cinemaapp',
                'desportoapp',
                'jornaisapp',
            ]
        }
    ],
    ao: [
        {
            title: 'Atualidade',
            submenus: [
                'sapo24',
                'renascenca',
                'jornali',
                'sol',
                'portocanal',
                'jornais',
                'henricartoon',
                'videos'
            ]
        },
        {
            title: 'Desporto',
            submenus: [
                'desporto',
                'bolabranca'
            ]
        },
        {
            title: 'Economia',
            submenus: [
                'eco'
            ]
        },
        {
            title: 'Vida',
            submenus: [
                'lifestyle',
                'mag',
                'viagens',
                'elle',
                'mood',
                'greensavers',
                'caesecompanhia',
                'rocknrio',
                'miranda'
            ]
        },
        {
            title: 'Tecnologia',
            submenus: [
                'tek',
                'pplware'
            ]
        },
        {
            title: 'Motores',
            submenus: [
                'kbb',
                'automonitor',
                'motosport',
                'automais'
            ]
        },
        {
            title: 'Serviços',
            submenus: [
                'blogs',
                'mail',
                'tempo',
                'farmacias',
                'transfer',
                'emprego'
            ]
        },
        {
            title: 'Shopping',
            submenus: [
                'promos',
                'auto',
                'autopremium',
                'autovendaja',
                'casa',
                'ticketline',
                'blueticket'
            ]
        },
        {
            title: 'Internacional',
            submenus: [
                'angola',
                'mocambique',
                'caboverde',
                'timor'
            ]
        }
    ],
    mz: [
        {
            title: 'Atualidade',
            submenus: [
                'sapo24',
                'renascenca',
                'jornali',
                'sol',
                'portocanal',
                'jornais',
                'henricartoon',
                'videos'
            ]
        },
        {
            title: 'Desporto',
            submenus: ['desporto', 'bolabranca']
        },
        {
            title: 'Economia',
            submenus: ['eco']
        },
        {
            title: 'Vida',
            submenus: [
                'lifestyle',
                'mag',
                'viagens',
                'elle',
                'mood',
                'greensavers',
                'caesecompanhia',
                'rocknrio',
                'miranda'
            ]
        },
        {
            title: 'Tecnologia',
            submenus: ['tek', 'pplware']
        },
        {
            title: 'Motores',
            submenus: [
                'kbb',
                'automonitor',
                'motosport',
                'automais'
            ]
        },
        {
            title: 'Serviços',
            submenus: [
                'blogs',
                'mail',
                'tempo',
                'farmacias',
                'transfer',
                'emprego'
            ]
        },
        {
            title: 'Shopping',
            submenus: [
                'promos',
                'auto',
                'autopremium',
                'autovendaja',
                'casa',
                'ticketline',
                'blueticket'
            ]
        }
    ],
    cv: [
        {
            title: 'Atualidade',
            submenus: [
                'sapo24',
                'renascenca',
                'jornali',
                'sol',
                'portocanal',
                'jornais',
                'henricartoon',
                'videos'
            ]
        },
        {
            title: 'Desporto',
            submenus: ['desporto', 'bolabranca']
        },
        {
            title: 'Economia',
            submenus: ['eco']
        },
        {
            title: 'Vida',
            submenus: [
                'lifestyle',
                'mag',
                'viagens',
                'elle',
                'mood',
                'greensavers',
                'caesecompanhia',
                'rocknrio',
                'miranda'
            ]
        },
        {
            title: 'Tecnologia',
            submenus: ['tek', 'pplware']
        },
        {
            title: 'Motores',
            submenus: [
                'kbb',
                'automonitor',
                'motosport',
                'automais'
            ]
        },
        {
            title: 'Serviços',
            submenus: [
                'blogs',
                'mail',
                'tempo',
                'farmacias',
                'transfer',
                'emprego'
            ]
        },
        {
            title: 'Shopping',
            submenus: [
                'promos',
                'auto',
                'autopremium',
                'autovendaja',
                'casa',
                'ticketline',
                'blueticket'
            ]
        }
    ],
    tl: [
        {
            title: 'Atualidade',
            submenus: [
                'sapo24',
                'renascenca',
                'jornali',
                'sol',
                'portocanal',
                'jornais',
                'henricartoon',
                'videos'
            ]
        },
        {
            title: 'Desporto',
            submenus: ['desporto', 'bolabranca']
        },
        {
            title: 'Economia',
            submenus: ['eco']
        },
        {
            title: 'Vida',
            submenus: [
                'lifestyle',
                'mag',
                'viagens',
                'elle',
                'mood',
                'greensavers',
                'caesecompanhia',
                'rocknrio',
                'miranda'
            ]
        },
        {
            title: 'Tecnologia',
            submenus: ['tek', 'pplware']
        },
        {
            title: 'Motores',
            submenus: [
                'kbb',
                'automonitor',
                'motosport',
                'automais'
            ]
        },
        {
            title: 'Serviços',
            submenus: [
                'blogs',
                'mail',
                'tempo',
                'farmacias',
                'transfer',
                'emprego'
            ]
        },
        {
            title: 'Shopping',
            submenus: [
                'promos',
                'auto',
                'autopremium',
                'autovendaja',
                'casa',
                'ticketline',
                'blueticket'
            ]
        }
    ]
};
