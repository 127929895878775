import * as config from './config.js';

const loginEndpoint = '#{LOGINENDPOINT}';

const ua = navigator.userAgent;

const createElement = ({ id = '', className = '', type, text = '', value = '', innerHTML = '', href = '' }) => {
    var element = document.createElement(type);
    id && element.setAttribute('id', id);
    //className && element.setAttribute('className', className);
    if (className) element.className = className;
    if (text) element.textContent = text;
    if (value) element.value = value;
    if (innerHTML) element.innerHTML = innerHTML;
    if (href) element.setAttribute('href', href);
    return element;
}

const getById = (id) => {
    return document.getElementById(id);
}

const getElement = (selector, parent) => {
    if (!selector) {
        return null;
    }
    parent = (!parent ? document : (typeof parent === 'string' ? getElement(parent) : parent));
    return parent.querySelector(selector);
}

const getAllElements = (selector) => document.querySelectorAll(selector);

const appendFromTemplate = (placeholder, templateHTML, where) => {
    placeholder.insertAdjacentHTML(where, templateHTML);
}

const isInPrivateMode = () => {
    if (typeof localStorage === 'object') {
        try {
            localStorage.setItem('localStorage', 1);
            return false;
        } catch (e) {
            return true;
        }
    }
}

const getRequest = (url, headers) => {
    return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function (data) {
            try {
                if (xhr.status >= 200 && xhr.status < 300) {
                    return resolve(JSON.parse(xhr.response));
                }
                else {
                    throw new Error();
                }
            } catch (e) {
                console.error(`Get Request: ${e}`);
                return reject(`Server Error`);
            }
        };
        xhr.open('GET', url);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.withCredentials = true;
        xhr.send();
    })
}

const fetchJSONP = (url, callback) => {
    return new Promise((resolve, reject) => {
        var sTag = document.createElement('script'),
            timeout = setTimeout(() => reject('Server Timeout'), 5000);
        sTag.setAttribute('type', 'text/javascript');
        sTag.src = url;

        window[callback] = (data) => {
            sTag && sTag.parentNode && sTag.parentNode.removeChild(sTag);
            clearTimeout(timeout);
            return resolve(data);
        }
        document.head.appendChild(sTag);
    });
}

const getUserInfo = () => getRequest(`${loginEndpoint}/Bsu/Min.json`).catch(() => false);

const isLogged = () => fetchJSONP(`${loginEndpoint}/CS.do?jsonCallback=loginCallback`, 'loginCallback')
    .then(data => data.auth)

const checkIfInDataRange = (iDate, fDate, dateToCheck) => {
    if (!iDate || !fDate) return true;
    let curDate = dateToCheck || new Date(),
        startDate = new Date(iDate.replace(' ', 'T')),
        endDate = new Date(fDate.replace(' ', 'T'));

    if (startDate.getTime && !isNaN(startDate.getTime()) && endDate.getTime && !isNaN(endDate.getTime())) {
        var msCurDate = curDate.getTime();
        var msStartDate = startDate.getTime();
        var msEndDate = endDate.getTime();

        if (msCurDate > msStartDate && msCurDate <= msEndDate) {
            return true;
        }
    }
    return false;
}

const addEvent = (elm, evt, cb) => {
    if (elm && evt && cb) {
        if (elm.addEventListener) {
            elm.addEventListener(evt, cb, true);
        }
        else if (elm.attachEvent) {
            elm.attachEvent('on' + evt, cb);
        }
    }
    else {
        window.addEventListener ? window.addEventListener(evt, cb, true) : window.attachEvent && window.attachEvent("on" + evt, function (evt) {
            cb(evt || window.event);
        });
    }
}

const createEvent = (eventName, data) => {
    let event;
    if (document.createEvent) {
        event = document.createEvent("HTMLEvents");
        event.initEvent(eventName, true, true);
    }
    else {
        event = document.createEventObject();
        event.eventType = eventName;
    }

    event.eventName = eventName;
    event.state = data;

    if (document.createEvent) {
        document.body.dispatchEvent(event);
    }
    else {
        document.body.fireEvent("on" + event.eventType, event);
    }
}

const getCookie = (cookieName) => {
    let cookie = document.cookie || false,
        _Cookie = {};
    if (cookie) {
        cookie = cookie.replace(new RegExp("; ", "g"), ';');
        let aCookie = cookie.split(';'),
            aItem = [];
        if (aCookie.length > 0) {
            for (let i = 0; i < aCookie.length; i++) {
                aItem = aCookie[i].split('=');
                if (aItem.length === 2) {
                    _Cookie[aItem[0]] = decodeURIComponent(aItem[1]);
                }
                aItem = [];
            }
        }
        if (cookieName) {
            if (cookieName in _Cookie) {
                return _Cookie[cookieName];
            }
            else {
                return null;
            }
        }
        return _Cookie;
    }
}

const setCookie = (name, value, expires, path, domain) => {
    if (!name || !value) {
        return;
    }
    let sNameValue,
        sExpires,
        sDomain,
        sPath;

    sNameValue = encodeURIComponent(name) + '=' + encodeURIComponent(value) + '';
    if (expires) {
        let oDate = new Date();
        oDate.setTime(oDate.getTime() + (expires * 1000));

        sExpires = '; expires=' + oDate.toUTCString() + '';
    }
    else {
        sExpires = '';
    }
    if (!domain) {
        sDomain = '; domain=' + window.location.host + '';
    }
    else {
        sDomain = '; domain=' + domain + '';
    }
    let portClean = new RegExp(":([^;]+)");
    sDomain = sDomain.replace(portClean, "");
    if (!path) {
        sPath = '; path=/';
    }
    else {
        sPath = '; path=' + path;
    }

    document.cookie = sNameValue + sExpires + sDomain + sPath;
}

const isAndroidMobile = () => ua.indexOf('Android') > -1;

const isIOSMobile = () => /iPad|iPhone|iPod/.test(ua) && !window.MSStream;

const isIOSSafari = () => isIOSMobile() && !ua.match(/CriOS/i);

const isEmpty = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;

const isMobile = () => isAndroidMobile() || isIOSMobile();

const hasXMLHttpRequest = () => !!XMLHttpRequest;

const isIE = () => {
    var myNav = navigator.userAgent.toLowerCase();
    return (myNav.indexOf('msie') != -1) ? parseInt(myNav.split('msie')[1]) : false;
}

const isBelowIE9 = () => isIE() && isIE() <= 9

const getMobileStoreKey = () => {
    if (ua.match(/(iPhone|iPod)/i)) {
        return 'ios_iphone';
    }
    else if (ua.match(/iPad/i)) {
        return 'ios_ipad';
    }
    else if (ua.match(/(Android.*Mobile|Mobile.*Android)/i)) {
        return 'android_mobile';
    }
    else if (ua.match(/Android/i)) {
        return 'android_tablet';
    }
    else if (ua.match(/IEMobile/i)) {
        return 'wp';
    }
}
const getElementWidth = (element) => {
    let style = window.getComputedStyle(element),
        width = element.offsetWidth,
        margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight),
        padding = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight),
        border = parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth);
    return width + margin - padding + border;
}

const createAndAttachIframe = (options, el) => {
    let $iframeElement = document.createElement('iframe');
    for (let option in options) {
        $iframeElement.setAttribute(option, options[option]);
    }
    el.appendChild($iframeElement);
}

const createMenuLi = (title, href, className) => {
    let $li = createElement({ type: 'li', className }),
        $a = createElement({ type: 'a', innerHTML: title });
    $a.setAttribute('href', href);
    $li.appendChild($a);
    return $li.outerHTML;
}

const cookieCounterCheck = (cookieObject) => {
    let hasCookie = getCookie(cookieObject.name),
        cookieNameToShow = cookieObject.nametoshow,
        cookieTTLToShow = cookieObject.ttltoshow,
        countCookieToShowLimit = cookieObject.pageViewCount,
        countCookieToShow = getCookie(cookieNameToShow);

    if (hasCookie) return false;
    if (!countCookieToShowLimit) return true;

    if (!countCookieToShow) {
        setCookie(cookieNameToShow, 1, cookieTTLToShow);
        return false;
    }
    else {
        countCookieToShow = parseInt(countCookieToShow, 10);
        if (isNaN(countCookieToShow)) {
            countCookieToShow = 0;
        }
        countCookieToShow = countCookieToShow + 1;
        if (countCookieToShow <= countCookieToShowLimit) {
            setCookie(cookieNameToShow, countCookieToShow, cookieTTLToShow);
            return false;
        }
    }
    return true;
}

const createPictureElement = (data, index, callback) => {

    let link = document.createElement('A');
    link.setAttribute('id', 'bsu-disruptive-link');
    link.setAttribute('href', '#');
    if (data.link) {
        link.onclick = function (evt) {
            window.open(data.link, '_blank');
            callback && callback();
        }
    }

    let picture = document.createElement('PICTURE');
    picture.setAttribute('class', 'bsu-disruptive-picture');

    link.appendChild(picture);

    Object.keys(data.steps[index]).forEach(function (key) {
        if (key !== 'img') {
            let source = document.createElement('SOURCE');
            source.setAttribute('srcSet', data.steps[index][key]);
            source.setAttribute('media', "(min-width:" + key + ")");
            picture.appendChild(source);
        }
    })

    if ('img' in data.steps[index]) {
        let img = document.createElement('IMG');
        img.setAttribute('src', data.steps[index].img);
        img.setAttribute('class', 'bsu-disruptive-picture');

        img.setAttribute('width', 1000);
        img.setAttribute('height', 562);

        picture.appendChild(img);
    }

    return link;

}

const buildDisruptiveStep = (stepIdx, imageData, callback) => {

    let container = document.querySelector('#bsu-disruptive-container-img')

    container.innerHTML = '';

    container.append(createPictureElement(imageData, stepIdx, callback));

    document.querySelector('#bsu-disruptive-wrapper').style.display = 'block';

    if (stepIdx < imageData.steps.length - 1) {
        stepIdx++;
        setTimeout(function () {
            buildDisruptiveStep(stepIdx, imageData, callback);
        }, imageData.timer * 1000);
    }
    else {
        setTimeout(function () {
            callback();
        }, imageData.timer * 1000);
    }
}

const validate404Page = () => {
    let is404 = document.body.getAttribute('data-path') === '/404';
    if (is404)
        return is404;

    for (const className of document.body.classList) {
        if (className.includes('404')) {
            is404 = true;
            break;
        }
    }
    return is404;
}

const getLocalStorage = (name) => {
    try {
        return JSON.parse(localStorage.getItem(name));
    }
    catch (err) {
        return null;
    }
}
const setLocalStorage = (name, value) => {
    let jsonData = JSON.stringify(value);
    localStorage.setItem(name, jsonData);
}
const deleteLocalStorage = (name) => {
    localStorage.setItem(name, null);
}

const deleteCookie = (name) => {
    document.cookie = `${name}=;domain=.sapo.pt;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
}

const deleteLegacyCookies = () => {
    try {
        deleteCookie('bsu-doodle-request');
        deleteCookie('bsu-v3-doodle');
        // deleteCookie('oauth_bid');
        // deleteCookie('oauth_sid');
        // deleteCookie('panoramaId');
        // deleteCookie('panoramaId_expiry');
        // deleteCookie('panoramaIdType');
    } catch (error) {
    }
}

module.exports = {
    deleteLegacyCookies,
    getLocalStorage,
    setLocalStorage,
    deleteLocalStorage,
    validate404Page,
    createElement,
    getById,
    getElement,
    getAllElements,
    appendFromTemplate,
    isInPrivateMode,
    getCookie,
    setCookie,
    fetchJSONP,
    checkIfInDataRange,
    addEvent,
    isAndroidMobile,
    isIOSMobile,
    isEmpty,
    getMobileStoreKey,
    isIOSSafari,
    isMobile,
    getElementWidth,
    createAndAttachIframe,
    createMenuLi,
    cookieCounterCheck,
    createEvent,
    getUserInfo,
    hasXMLHttpRequest,
    isLogged,
    buildDisruptiveStep,
    isBelowIE9
}
