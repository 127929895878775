import * as Util from '../../util.js';
import { menuClasses, liWithSubmenuClass } from '../../classes-ids.config.js';
import * as menuEntries from '../../menus/menu-entries.config';

export default class EventHandlers {

    constructor(bsu) {
        this.bsuWidget = bsu;
        this.$bsuEl = Util.getById(menuClasses.mainId);
        this.$bsuLinkUl = Util.getById(menuClasses.mainUl.id);
        this.$bsuLogo = Util.getById(menuClasses.logoId);
        this.$userMenu = Util.getById(menuClasses.userMenu.id);
        this.$liWithSubmenu = this.$bsuLinkUl ? Array.from(this.$bsuLinkUl.getElementsByClassName(liWithSubmenuClass)) : [];
    }

    init() {
        return Promise.resolve().then(() => {
            resizeEvents.call(this);
            initButtonHandlers.call(this);
        }).catch((e) => console.warn(`Erro no Event Handlers : ${e}`));
    }
}

function initButtonHandlers() {
    let _self = this;

    Util.addEvent(document, 'click', (e) => {
        if (e.target.closest(`#${menuClasses.userMenu.id}`)) {
            let $openedMenu = this.$bsuLinkUl && this.$bsuLinkUl.getElementsByClassName(menuClasses.openedSubmenu)[0];
            $openedMenu && $openedMenu.removeClass(menuClasses.openedSubmenu);
        }
        else{
            if (e.target.closest(`#${menuClasses.mainId}`)) {
                if (!e.target.closest(`#${menuClasses.moreMenu.ulId}`)) {
                    if(e.target.hasAttribute('data-disrupt') || (e.target.closest('a') && e.target.closest('a').getAttribute('id') === 'bsu-v3-logo') ){

                        let disruptCookie = Util.getCookie('_bsu_disrupt');

                        if(!disruptCookie){

                            let menuItem = e.target.closest('a').getAttribute('id') === 'bsu-v3-logo'?
                                menuEntries['logo']:menuEntries[e.target.getAttribute('data-disrupt')];

                            let imageData = menuItem.disruptiveImage;

                            if(imageData && imageData.steps && imageData.steps.length > 0){

                                e.preventDefault();

                                Util.setCookie("_bsu_disrupt", '1', 60 * 60 * 24, '/', '.sapo.pt');

                                if(!document.querySelector('#bsu-disruptive-wrapper')){
                                    let disruptiveWrapper = document.createElement('DIV');
                                    disruptiveWrapper.setAttribute('id', 'bsu-disruptive-wrapper');

                                    let disruptiveDiv = document.createElement('DIV');
                                    disruptiveDiv.setAttribute('id', 'bsu-disruptive-container');

                                    let disruptiveImgDiv = document.createElement('DIV');
                                    disruptiveImgDiv.setAttribute('id', 'bsu-disruptive-container-img');

                                    disruptiveDiv.appendChild(disruptiveImgDiv)
                                    disruptiveWrapper.appendChild(disruptiveDiv)
                                    document.body.appendChild(disruptiveWrapper);
                                }

                                Util.buildDisruptiveStep(0, imageData, function(){
                                    document.querySelector('#bsu-disruptive-wrapper').style.display = 'none';
                                    if(e.target.closest('a').getAttribute('id') === 'bsu-v3-logo'){
                                        window.location.href = e.target.closest('a').getAttribute('href');
                                    }else{
                                        window.location.href = e.target.getAttribute('href');
                                    }

                                });
                            }
                            else{

                                if(e.target.hasAttribute('data-disrupt')){
                                    window.location.href = e.target.getAttribute('href');
                                }
                                else{
                                    window.location.href = e.target.closest('a').getAttribute('href');
                                }
                            }

                        }

                    }
                }
            }
        }
    });

    this.$liWithSubmenu.forEach(li => {
        Util.addEvent(li, 'click', function (e) {
            if(e.target.closest(`#${menuClasses.moreMenu.ulId}`)) return;

            if (this.hasClass(menuClasses.openedSubmenu) && !e.target.contains(Util.getElement(menuClasses.moreMenu.ul))) {
                // _self.$bsuEl.removeClass('is-open')
                this.removeClass(menuClasses.openedSubmenu);
                return;
            }

            // _self.$bsuEl.addClass('is-open')
            let $openedMenu = _self.$bsuLinkUl.getElementsByClassName(menuClasses.openedSubmenu)[0];
            $openedMenu && $openedMenu.removeClass(menuClasses.openedSubmenu);
            this.addClass(menuClasses.openedSubmenu);
        });
    });

}

function resizeEvents() {
    let _self = this;
    Util.addEvent(window, 'resize', function (e) {
        if (window.innerWidth > 640 && window.innerWidth <= 1440)
            removeBSUElements.call(_self);
    });
    removeBSUElements.call(_self);
}

function removeBSUElements() {
    let $liList = this.$bsuLinkUl ? Array.from(this.$bsuLinkUl.children) : [];

    $liList.forEach(li => {
        li.style.display = '';
    })

    $liList.reverse().some((li, idx, reversedArray) => {
        if (idx && !li.hasClass(menuClasses.menuSeparators)) {
            let bsuElementsSum = Util.getElementWidth(this.$bsuLinkUl)
                + Util.getElementWidth(this.$bsuLogo) + Util.getElementWidth(this.$userMenu);
            if (bsuElementsSum + 10 > this.bsuWidget.$container.offsetWidth) {
                li.style.display = 'none';
                // if (reversedArray[idx + 1].hasClass(menuClasses.menuSeparators)) {
                //     reversedArray[idx + 1].style.display = 'none';
                // }
                //Servia para os separators
                return this.bsuWidget.$container.offsetWidth > Util.getElementWidth(this.$bsuLinkUl)
                    + Util.getElementWidth(this.$bsuLogo) + Util.getElementWidth(this.$userMenu) - Util.getElementWidth(li);
            }
        }
    })

    let mainUl = Util.getById(menuClasses.mainUl.id);
    mainUl && (mainUl.style.display = '');
}
