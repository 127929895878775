import * as Util from '../../util.js';
import * as config from '../../config.js';

export default class SocialNetworks {
    constructor(bsu) {
        this.bsuWidget = bsu;
        this.bsuWidget.createBannersContainer();
        this.$appsContainer = this.bsuWidget.$bannersContainer;
        this.controlCookie = config.cookieBanners.socialnetworks;
    }

    init() {
        if (!this.bsuWidget.isShowingBanner) {
            return Promise.resolve().then(() => createBanner.call(this))
                .catch((e) => {
                    console.warn(`Erro no Social : ${e}`);
                    return Promise.reject(e);
                });
        } else {
            console.warn(`Erro no Social: Prioritary banner already displaying`);
            return Promise.reject(`Prioritary banner already displaying`);
        }
    }
}

function createBanner() {
    return new Promise((resolve, reject) => {
        if (!Util.cookieCounterCheck(this.controlCookie)) {
            return reject('Already shown/page views left for SocialNetworks banner');
        }
        let socialLinks = this.bsuWidget._dataOptions.socialLinks;
        let countryLinks = Object.keys(socialLinks).reduce((html,key) => {
            let link = socialLinks[key];
            if(link) {
                let $a = Util.createElement({type:'a', className: `bsu-v3-button bsu-v3-button-${key}`});
                $a.href = link;
                $a.innerHTML = config.socialNetworks[key].name;
               html += $a.outerHTML;
            }
            return html;
        }, '');

        
        if(!countryLinks) return reject('No social links');

        let markup =
            `<li>
                <div class="bsu-v3-ntf bsu-v3-ntf-social-networks">
                    <div>${config.socialNetworkText}</div>
                    <div class="bsu-v3-buttons">
                        ${countryLinks}
                    </div>
                    <a id="bsu-v3-banner-dismiss" class="bsu-v3-ntf-dismiss" href="#fechar">
                        <span>Fechar</span>
                        <i class="bsu-v3-icon-close"></i>
                    </a>
                </div>
            </li>`,
            _self = this;
        this.$appsContainer.innerHTML = markup;
        Util.addEvent(Util.getById('bsu-v3-banner-dismiss'), 'click', function (e) { e.preventDefault(); closeBannerClick.call(_self, this); });
        this.bsuWidget.showBannersContainer();
        return resolve();
    })
}

function closeBannerClick(element) {
    let $closestLi = element.closest('li');
    $closestLi.parentNode.removeChild($closestLi);
    this.bsuWidget.removeBannerContainerIfEmpty();
    Util.setCookie(this.controlCookie.name, '1', this.controlCookie.ttl);
}