export const liWithSubmenuClass = 'bsu-v3-has-submenu';

export const menuClasses = {
    bsuContainerId: 'bsu-v3-ctn',
    mainId: 'bsu-v3',
    placeholderId: 'bsu-placeholder',
    logoId: 'bsu-v3-logo',
    openedSubmenu: 'bsu-v3-has-submenu--open-submenu',
    menuSeparators: 'bsu-v3-sep',
    bannerContainer: 'bsu-v3-ntfs',
    darkBgClass: 'bsu-v3-dark-bg',
    mainUl: {
        classes: 'bsu-v3-menu bsu-v3-menu-links-menu',
        id: 'linksMenu'
    },
    userMenu: {
        id: 'userMenu',
        logoutId: 'bsuv3Logout',
        userAreaId: 'bsuv3UserArea',
        userMenuOptionsId: 'userMenuOptions',
        avatarId: 'bsuv3UserAvatar',
        mailIconId: 'mailIcon',
        mailIframeId: 'mailIframeContainer',
        mailAreaId: 'bsuv3MailArea',
        mailCounterId: 'mailCounter',
        mailPopupOpenClass: 'bsu-v3-email-preview--open-preview',
        myEmailId: 'bsuv3MyEmail'
    },
    collapsable: {
        li: `${liWithSubmenuClass} bsu-v3-hide-tiny bsu-v3-hide-small bsu-v3-hide-large bsu-v3-hide-xlarge bsu-v3-hide-all`,
        subMenuUl: 'bsu-v3-submenu'
    },
    largeMenu: {
        li: 'bsu-v3-hide-tiny bsu-v3-hide-small _bsu-v3-hide-medium',
        separator: 'bsu-v3-sep bsu-v3-hide-tiny bsu-v3-hide-small _bsu-v3-hide-medium',
        newEntry: 'bsu-v3-new'
    },
    moreMenu : {
        li: `bsu-v3-more ${liWithSubmenuClass}`,
        span: '_bsu-v3-hide-tiny',
        div: 'bsu-v3-give-it-to-me-baby',
        ul: 'bsu-v3-sapo-menu bsu-v3-clearfix',
        ulId: 'bsuV3SapoMenu',
        section: 'bsu-v3-sapo-menu-section',
        h6: 'bsu-v3-sapo-menu-section-title',
        subUl: 'bsu-v3-sapo-menu-section-list'
    },
    footer: {
        footerLinksId: 'bsu-footer-links',
        footerContainerId: 'bsu-footer'
    },
    stylesheets: {
        backgroundColor: 'bsu-background-color-css',
        mainColor: 'bsu-background-main-css',
        main: 'bsu-main-css'
    },
    scripts: {
        newScript: 'newScript'
    }
}