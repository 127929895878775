import * as Util from '../../util.js';
import * as config from '../../config.js';

export default class AppsStores {

    constructor(bsu) {
        this.bsuWidget = bsu;
        this.bsuWidget.createBannersContainer();
        this.$appsContainer = this.bsuWidget.$bannersContainer;
        this.mobileStoreKey = Util.getMobileStoreKey();
        this.appBannerObj;
    }

    init(apps) {
        if (!Util.isEmpty(apps) && !this.bsuWidget.isShowingBanner) {
            return Promise.resolve().then(() => getAppBannerObject.call(this, apps))
                .then(() => createAppsPromotionBanner.call(this))
                .catch((e) => {
                    console.warn(`Erro no AppStores : ${e}`);
                    return Promise.reject(e);
                });
        } else {
            console.warn(`No Apps configured in Apps Banner or prioritary banner already displaying`);
            return Promise.reject(`No Apps configured in Apps Banner or prioritary banner already displaying`);
        }
    }

    createAppsPromotionMenu(apps) {
        return getAppBannerObject.call(this, apps).then(() => {
            let containers = this.bsuWidget._dataOptions.appStoreContainers ?
                this.bsuWidget._dataOptions.appStoreContainers.split('|') : null;
            if (!containers || !containers.length) {
                console.warn(`Extra containers for stores promotion don't exist in this page`);
                return;
            }
            containers.forEach(container => {
                let $container = Util.getElement(container);
                if ($container) {
                    $container.appendChild(createStoresMarkup.call(this));
                } else {
                    console.warn(`Extra container ${container} for stores promotion doesn't exist in this page`);
                }
            })
        })
    }
}

function getAppBannerObject(apps) {
    return new Promise((resolve, reject) => {
        let currentHost = this.bsuWidget._config.host,
            currentHostname = this.bsuWidget._config.hostname,
            appBannerObj;

        if (currentHost.indexOf('www.') === 0) {
            currentHost = currentHost.replace(/^www\./i, '');
        }

        if (currentHostname in apps) {
            let appInfo = apps[currentHostname],
                currentURL = window.location.href;
            appBannerObj = appInfo.reduce((finalAppObj, app) => {
                if (currentURL.indexOf(currentHost + app.basepath) > -1) {
                    return app;
                }
            }, {});

            if (appBannerObj) {
                this.appBannerObj = appBannerObj;
                return resolve(appBannerObj);
            }
            else {
                return reject(`This domain has no Apps configured in AppBanners`);
            }
        } else {
            return reject(`This domain has no Apps configured in AppBanners`);
        }
    })
}

function createAppsPromotionBanner() {
    if (!Util.cookieCounterCheck(config.cookieBanners.app)) {
        throw new Error('Already shown/Page views left App Store banner');
    }

    let $liContainer = Util.createElement({ type: 'li' }),
        $liDivContainer = Util.createElement({ type: 'div', className: 'bsu-v3-ntf bsu-v3-ntf-app bsu-v3-has-icon' }),
        $storesUl = createStoresMarkup.call(this),
        markup =
            `<img src="${this.appBannerObj.icon}" alt="${this.appBannerObj.name.replace(/\"/, '\"')}" class="bsu-v3-ntf-app-icon">
                <h6 class="bsu-v3-ntf-app-title">${this.appBannerObj.name.replace(/\"/, '\"')}</h6>
                <div class="bsu-v3-ntf-app-pitch">${this.appBannerObj.promoText}</div>
                ${$storesUl.outerHTML}`;

    $liDivContainer.innerHTML = markup;
    $liContainer.appendChild($liDivContainer);
    createBannersCloseButton.call(this, $liDivContainer, $liContainer, e => bannerCloseButtonCallback.call(this, e));

    this.$appsContainer.appendChild($liContainer);
    this.bsuWidget.showBannersContainer();
}

function createStoresMarkup() {
    let sufix = '-page-container',
        $ulContainerElm = Util.createElement({ type: 'ul', className: `bsu-v3-ntf-app-badges` }),
        markup = '',
        stores;

    stores = normalizeStores.call(this, this.appBannerObj.stores);

    for (var k in stores) {
        if (!stores.hasOwnProperty(k)) {
            continue;
        }
        markup +=
            `<li class="bsu-v3-ntf-app-badge-${k}${(stores[k] ? '' : ' bsu-v3-ntf-app-badge-unavailable')}">
            ${(stores[k] && stores[k].link ? `<a href="${stores[k].link}" class="bsu-v3-tracklink" data-trackerlink="banner|link_deskapp${sufix}|${stores[k].analyticsKey}">
                <img src="${stores[k].img}" alt="${stores[k].alt}" /></a>` : '')}
            </li>`;
    }

    Util.isAndroidMobile() && $ulContainerElm.addClass('is_android');
    Util.isIOSMobile() && $ulContainerElm.addClass('is_ios');

    $ulContainerElm.innerHTML = markup;
    return $ulContainerElm;
}

function normalizeStores(stores) {
    var res = {};
    if ('ios_iphone' in stores) {
        res.ios = stores.ios_iphone;
    } else if ('ios_ipad' in stores) {
        res.ios = stores.ios_ipad;
    }

    if ('android_mobile' in stores) {
        res.android = stores.android_mobile;
    } else if ('android_tablet' in stores) {
        res.android = 'android_tablet';
    }

    if (res.ios) {
        res.ios.analyticsKey = 'store_ios';
        res.ios.img = `${this.bsuWidget._paths.assets}/badge-ios.png?v=${this.bsuWidget._version}`;
        res.ios.alt = 'Descarregar na App Store';
    }
    if (res.android) {
        res.android.analyticsKey = 'store_android';
        res.android.img = `${this.bsuWidget._paths.assets}/badge-google-play.png?v=${this.bsuWidget._version}`;
        res.android.alt = 'Disponivel no Google Play';
    }

    return res;
}

function createBannersCloseButton(parentElm, refToCloseElm, callBack) {
    let $aLink = Util.createElement({ type: 'a', className: 'bsu-v3-ntf-dismiss' });
    $aLink.setAttribute('href', '#fechar');
    $aLink.appendChild(Util.createElement({ type: 'span', text: 'Fechar' }));
    $aLink.appendChild(Util.createElement({ type: 'i', className: 'bsu-v3-icon-close' }));

    parentElm.appendChild($aLink);

    let _self = this;
    Util.addEvent($aLink, 'click', function (event) {
        event.preventDefault();
        let $closestLi = this.closest('li');
        $closestLi.parentNode.removeChild($closestLi);
        _self.bsuWidget.removeBannerContainerIfEmpty();
        callBack(event);
    });
}

function bannerCloseButtonCallback(event) {
    Util.setCookie(config.cookieBanners.app.name, '1', config.cookieBanners.app.ttl, this.appBannerObj.basepath); // bsuv2app
}