import * as Util from "../../util.js";
import * as config from "../../config.js";

export default class PushAndNewsletter {
    constructor(bsu) {
        this.bsuWidget = bsu;
        this.bsuWidget.createBannersContainer();
        this.$appsContainer = this.bsuWidget.$bannersContainer;
        this.$newsletterButton = Util.getElement(this.bsuWidget._dataOptions.newsletterSelector) || null;
        this.$pushntfsButton = Util.getElement(this.bsuWidget._dataOptions.pushntfsSelector) || null;
        this.controlCookie = config.cookieBanners.pushandnewsletter;
    }

    init() {
        if (!this.bsuWidget.isShowingBanner && (this.$newsletterButton || this.$pushntfsButton)) {
            return Promise.resolve()
                .then(() => createBanner.call(this))
                .catch(e => {
                    console.warn(`Erro no PushAndNewsletter : ${e}`);
                    return Promise.reject(e);
                });
        } else {
            console.warn(`Erro no PushAndNewsletter: No buttons on page or prioritary banner already displaying`);
            return Promise.reject(`Erro no PushAndNewsletter: No buttons on page or prioritary banner already displaying`);
        }
    }
}

function createBanner() {
    return new Promise((resolve, reject) => {
        if (!Util.cookieCounterCheck(this.controlCookie) || (!this.$newsletterButton && !this.$pushntfsButton)) {
            return reject("Already shown/page views left PushNewsletter banner");
        }

        let pushNewsletterText = this.$newsletterButton && this.$pushntfsButton ? config.pushNewsletterText 
            : (this.$newsletterButton ? config.newsletterText : config.pushText);

        let markup = `<li>
                <div class="bsu-v3-ntf bsu-v3-ntf-subscriptions">
                    <div>${pushNewsletterText}</div>
                    <div class="bsu-v3-buttons">
                        ${this.$newsletterButton ? '<button id="bsu-v3-newsletter-btn" class="bsu-v3-button bsu-v3-button-newsletter">Newsletter</button>' : ""}
                        ${this.$pushntfsButton ? '<button id="bsu-v3-notifications-btn" class="bsu-v3-button bsu-v3-button-notifications">Notificações</button>' : ""}
                    </div>
                    <a id="bsu-v3-banner-dismiss" class="bsu-v3-ntf-dismiss" href="#fechar">
                        <span>Fechar</span>
                        <i class="bsu-v3-icon-close"></i>
                    </a>
                </div>
            </li>`,
            _self = this;
        this.$appsContainer.innerHTML = markup;
        this.$newsletterButton && Util.addEvent(Util.getById("bsu-v3-newsletter-btn"), "click", () => window["SAPO"] && window["SAPO"].NEWSLETTERS && window["SAPO"].NEWSLETTERS.open());
        this.$pushntfsButton && Util.addEvent(Util.getById("bsu-v3-notifications-btn"), "click", () => this.$pushntfsButton.click());
        Util.addEvent(Util.getById("bsu-v3-banner-dismiss"), "click", function (e) {
            e.preventDefault();
            closeBannerClick.call(_self, this);
        });
        this.bsuWidget.showBannersContainer();
        return resolve();
    });
}

function closeBannerClick(element) {
    let $closestLi = element.closest("li");
    $closestLi.parentNode.removeChild($closestLi);
    this.bsuWidget.removeBannerContainerIfEmpty();
    Util.setCookie(this.controlCookie.name, "1", this.controlCookie.ttl);
}
