module.exports = {
    env: '%ENV%',
    cookieBanners: {
        app: {
            name: 'bsuv3app',
            ttl: (60 * 60 * 24 * 30),
            nametoshow: 'bsuv3countapp',
            ttltoshow: (60 * 60 * 24),
            pageViewCount: 3,
        },
        pushandnewsletter: {
            name: 'bsuv3push',
            ttl: (60 * 60 * 24 * 30),
            nametoshow: 'bsuv3countpush',
            ttltoshow: (60 * 60 * 24),
            pageViewCount: 3,
        },
        socialnetworks:{
            name: 'bsuv3social',
            ttl: (60 * 60 * 24 * 30),
            nametoshow: 'bsuv3countsocial',
            ttltoshow: (60 * 60 * 24),
            pageViewCount: 4,
        },
        // ntfs: {
        //     name: 'bsuv2ntfs-',
        //     ttl: (60 * 60 * 24 * 3)
        // },
        cookie: {
            name: '_ckiemsg',
            ttl: 9999999999999,
        }
    },
    homeScreenText: {
        android: 'Adicione um atalho no seu telemóvel. Seleccione ";Adicionar ao ecrã; principal"; na tecla Menu',
        ios: 'Adicione um atalho no seu telemóvel. Clique no ícone de partilha e depois em "Adicionar ao ecrã; principal"'
    },
    pushNewsletterText: 'Subscreva a Newsletter e as Notificações e receba toda a informação em primeira mão.',
    newsletterText: 'Subscreva a Newsletter e receba toda a informação em primeira mão.',
    pushText: 'Subscreva as Notificações e receba toda a informação em primeira mão.',
    socialNetworkText: 'Siga-nos nas redes sociais.',

    socialNetworks: {
        facebook: {
            name: `Facebook`
        },
        twitter:{
            name: `Twitter`
        },
        instagram:{
            name: `Instagram`
        }
    }
}
