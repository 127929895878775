import BSU from './bsu';

(function () {
    let bsu = new BSU();
    if (typeof Promise !== 'function') {
        var js = document.createElement('script');
        js.src = `${window.location.protocol}//js.sapo.pt/Projects/utils/promise-7.0.4.min.js`;
        js.onload = function () {
            bsu.init();
        };
        document.head.appendChild(js);
    } else {
        bsu.init();
    }

})();
